<template>

    <div id="app"
        class="archive category category-tech-news category-19 wp-custom-logo unselectable elementor-page-5179 elementor-default elementor-template-full-width elementor-kit-5">
        <Header></Header>
        <div style="margin-top:73px;" data-elementor-type="archive" data-elementor-id="5179"
            class="elementor elementor-5179 elementor-location-archive" data-elementor-post-type="elementor_library">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-94d7a27 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="94d7a27" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9b8f969"
                        data-id="9b8f969" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-492ee8e elementor-widget elementor-widget-spacer"
                                data-id="492ee8e" data-element_type="widget" data-widget_type="spacer.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-spacer">
                                        <div class="elementor-spacer-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-58bcb56 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
                data-id="58bcb56" data-element_type="section"
                data-settings='{"background_background":"classic","animation":"none"}'>
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0d25f74"
                        data-id="0d25f74" data-element_type="column"
                        data-settings='{"background_background":"gradient"}'>
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-978fff8 elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-invisible elementor-widget elementor-widget-image"
                                data-id="978fff8" data-element_type="widget" data-settings='{"_animation":"fadeIn"}'
                                data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img width="310" height="158"
                                        src="../assets/techNews/Screenshot_6-1.png"
                                        class="attachment-large size-large wp-image-4071" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-63fe07f elementor-invisible elementor-widget elementor-widget-heading"
                                data-id="63fe07f" data-element_type="widget"
                                data-settings='{"_animation":"fadeInUp","_animation_delay":1}'
                                data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">
                                        金錢兔賜予您無限好運及財富!
                                    </h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-e607822 elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-invisible elementor-widget elementor-widget-text-editor"
                                data-id="e607822" data-element_type="widget"
                                data-settings='{"_animation":"fadeInUp","_animation_delay":2}'
                                data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p>
                                        <span style="white-space: pre-wrap">金钱兔模式将可能在任何旋转期间被随机触发，并获得8次财富旋转!<br /></span>
                                    </p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-52f5b1a elementor-align-left elementor-widget__width-initial elementor-mobile-align-center elementor-widget-mobile__width-initial elementor-invisible elementor-widget elementor-widget-button"
                                data-id="52f5b1a" data-element_type="widget"
                                data-settings='{"_animation":"fadeInUp","_animation_mobile":"fadeInUp","_animation_delay":3}'
                                data-widget_type="button.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-button-wrapper">
                                        <a @click.prevent="$router.push('/xboxOne')" class="elementor-button elementor-button-link elementor-size-xs"
                                            href="https://pgsoftgaming.com/pggame/">
                                            <span class="elementor-button-content-wrapper">
                                                <span class="elementor-button-icon">
                                                    <i aria-hidden="true" class="fas fa-gamepad"></i>
                                                </span>
                                                <span class="elementor-button-text">游戏試玩</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-b08321f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="b08321f" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5d30268"
                        data-id="5d30268" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-c6de865 elementor-widget elementor-widget-theme-archive-title elementor-page-title elementor-widget-heading"
                                data-id="c6de865" data-element_type="widget"
                                data-widget_type="theme-archive-title.default">
                                <div class="elementor-widget-container">
                                    <h1 class="elementor-heading-title elementor-size-default">
                                        {{ navList && navList[0] && navList[0].topicName }}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-5e16b24 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="5e16b24" data-element_type="section">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-31271ba"
                        data-id="31271ba" data-element_type="column"
                        data-settings='{"background_background":"classic"}'>
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-52d6727 elementor-grid-3 elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-posts--thumbnail-top elementor-widget elementor-widget-archive-posts"
                                data-id="52d6727" data-element_type="widget"
                                data-settings='{"pagination_type":"numbers_and_prev_next","archive_classic_columns":"3","archive_classic_columns_tablet":"2","archive_classic_columns_mobile":"1","archive_classic_row_gap":{"unit":"px","size":35,"sizes":[]},"archive_classic_row_gap_tablet":{"unit":"px","size":"","sizes":[]},"archive_classic_row_gap_mobile":{"unit":"px","size":"","sizes":[]}}'
                                data-widget_type="archive-posts.archive_classic">
                                <div class="elementor-widget-container">
                                    <div
                                        class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                        <article
                                            @click.prevent="selectTab(techItem.id)"
                                            v-for="(techItem,techIndex) in navList" :key="techIndex"
                                            class="elementor-post elementor-grid-item post-28720 post type-post status-publish format-standard has-post-thumbnail hentry category-tech-news">
                                            <a class="elementor-post__thumbnail__link"
                                                href="#"
                                                tabindex="-1">
                                                <div class="elementor-post__thumbnail">
                                                    <img style="object-fit: cover;object-position: center;height: 128px;" fetchpriority="high" width="1080" height="776"
                                                        :src="$imgUrl + techItem.titlePic"
                                                        class="attachment-medium size-medium wp-image-28710" alt="" />
                                                </div>
                                            </a>
                                            <div class="elementor-post__text">
                                                <h3 class="elementor-post__title">
                                                    <a href="#">
                                                        {{ techItem.title }}
                                                    </a>
                                                </h3>
                                                <div class="elementor-post__meta-data">
                                                    <span class="elementor-post-date">
                                                        {{ $formatDate(techItem.updateTime) }}
                                                    </span>
                                                </div>
                                                <div class="elementor-post__excerpt">
                                                    <p>{{ techItem.description }}</p>
                                                </div>
                                            </div>
                                        </article>
                                        

                                    </div>

                                    <div style="display: flex;justify-content: center;" v-if="navList.length>0">
                                        <el-pagination
                                            layout="prev, pager, next"
                                            @current-change="currentChange"
                                            :total="totalRow">
                                        </el-pagination>
                                    </div>

                                    <!-- <div class="e-load-more-anchor" data-page="1" data-max-page="174"
                                        data-next-page="https://www.pgsoftgaming.com/category/tech-news/page/2/"></div>
                                    <nav class="elementor-pagination" aria-label="Pagination">
                                        <span class="page-numbers prev">&laquo; </span>
                                        <span aria-current="page" class="page-numbers current"><span
                                                class="elementor-screen-only">Page</span>1</span>
                                        <a class="page-numbers"
                                            href="https://www.pgsoftgaming.com/category/tech-news/page/2/"><span
                                                class="elementor-screen-only">Page</span>2</a>
                                        <a class="page-numbers"
                                            href="https://www.pgsoftgaming.com/category/tech-news/page/3/"><span
                                                class="elementor-screen-only">Page</span>3</a>
                                        <span class="page-numbers dots">&hellip;</span>
                                        <a class="page-numbers"
                                            href="https://www.pgsoftgaming.com/category/tech-news/page/20/"><span
                                                class="elementor-screen-only">Page</span>20</a>
                                        <a class="page-numbers next"
                                            href="https://www.pgsoftgaming.com/category/tech-news/page/2/">&raquo;</a>
                                    </nav> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-671febe" data-id="671febe" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-1031938 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1031938" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-fe9d385" data-id="fe9d385" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-782cd09 elementor-widget elementor-widget-shortcode" data-id="782cd09" data-element_type="widget" data-widget_type="shortcode.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-shortcode">
                                                                <div data-elementor-type="section" data-elementor-id="31562" class="elementor elementor-31562" data-elementor-post-type="elementor_library">
                                                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-4313ba0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4313ba0" data-element_type="section">
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-47dc7b6" data-id="47dc7b6" data-element_type="column">
                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                    <section   v-for="(asiderItem,asiderIndex) in asiderList" :key="asiderIndex" class="elementor-section elementor-inner-section elementor-element elementor-element-6eee4eb elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6eee4eb" data-element_type="section">
                                                                                        <div v-if="asiderItem.topicName!='社会新闻'" class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2cd72dd" data-id="2cd72dd" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-b78bd3e elementor-widget elementor-widget-heading" data-id="b78bd3e" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">{{ asiderItem.topicName }}</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-5374539 elementor-grid-2 elementor-grid-mobile-2 elementor-grid-tablet-2 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts" data-id="5374539" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;2&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article @click.prevent="selectTab(articleItem.id)" v-for="(articleItem,articleIndex) in asiderItem.articleEntities" :key="articleIndex" class="elementor-post elementor-grid-item post-32469 post type-post status-publish format-standard has-post-thumbnail hentry category-games">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/games/2024/07/32469/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;"  loading="lazy" decoding="async" width="500" height="149" :src="$imgUrl + articleItem.titlePic" class="attachment-medium size-medium wp-image-32467" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/games/2024/07/32469/">{{ articleItem.title }}</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                    <!-- <section class="elementor-section elementor-inner-section elementor-element elementor-element-fd01a28 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="fd01a28" data-element_type="section">
                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f73aad7" data-id="f73aad7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-6d58b2c elementor-widget elementor-widget-heading" data-id="6d58b2c" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">体育专栏</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-a050e87 elementor-grid-2 elementor-grid-mobile-2 elementor-grid-tablet-2 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts" data-id="a050e87" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;2&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article class="elementor-post elementor-grid-item post-32470 post type-post status-publish format-standard has-post-thumbnail hentry category-tiyu">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/tiyu/2024/07/32470/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;" loading="lazy" decoding="async" width="660" height="384" src="../assets/news/2024071900_激动人心的体育投注讲解：46岁阿根廷少帅3年4连冠传奇_.jpg" class="attachment-medium size-medium wp-image-32468" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/tiyu/2024/07/32470/">激动人心的体育投注讲解：46岁阿根廷少帅3年4连冠传奇			</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                <article class="elementor-post elementor-grid-item post-32435 post type-post status-publish format-standard has-post-thumbnail hentry category-tiyu">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/tiyu/2024/07/32435/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;" loading="lazy" decoding="async" width="799" height="532" src="../assets/news/2024071800_2024欧洲国家盃最终决赛：西班牙vs英格兰_体育下注全攻略_.jpg" class="attachment-medium size-medium wp-image-32434" alt=""  sizes="(max-width: 799px) 100vw, 799px"/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/tiyu/2024/07/32435/">2024欧洲国家盃最终决赛：西班牙vs英格兰 体育下注全攻略			</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section> -->
                                                                                    <section style="margin-top: -10px;" class="elementor-section elementor-inner-section elementor-element elementor-element-90f5ed1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="90f5ed1" data-element_type="section">
                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-fcd30b3" data-id="fcd30b3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-e8be210 elementor-widget elementor-widget-heading" data-id="e8be210" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">热门焦点</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-a2b8db6 elementor-grid-1 elementor-posts--thumbnail-left elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-widget elementor-widget-posts" data-id="a2b8db6" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;1&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;1&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article @click.prevent="selectTab(shItem.id)" v-for="(shItem,shIndex) in shNewsList" :key="shIndex" class="elementor-post elementor-grid-item post-33231 post type-post status-publish format-standard has-post-thumbnail hentry category-china-news">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/china-news/2024/09/33231/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 70px;" fetchpriority="high" decoding="async" width="600" height="900" :src="$imgUrl + shItem.titlePic" class="attachment-medium size-medium wp-image-33224" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/china-news/2024/09/33231/">{{  shItem.title }}</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                </div>
            </section>
        </div>

        <div data-elementor-type="popup" data-elementor-id="235"
            class="elementor elementor-235 elementor-location-popup"
            data-elementor-settings='{"entrance_animation":"slideInRight","exit_animation":"slideInRight","avoid_multiple_popups":"yes","entrance_animation_duration":{"unit":"px","size":0.5,"sizes":[]},"a11y_navigation":"yes","triggers":[],"timing":[]}'
            data-elementor-post-type="elementor_library">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-16c9f3ef elementor-section-full_width elementor-section-height-min-height elementor-section-items-stretch elementor-section-content-middle elementor-section-height-default"
                data-id="16c9f3ef" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-container elementor-column-gap-no">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0222bbd"
                        data-id="0222bbd" data-element_type="column"
                        data-settings='{"background_background":"classic"}'>
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-background-overlay"></div>
                            <div class="elementor-element elementor-element-7b86483 elementor-widget elementor-widget-image"
                                data-id="7b86483" data-element_type="widget" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" width="404" height="238"
                                        src="https://www.pgsoftgaming.com/wp-content/uploads/PGLOGO2024.png"
                                        class="attachment-large size-large wp-image-26901" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-7237f52 elementor-nav-menu--dropdown-none elementor-widget elementor-widget-nav-menu"
                                data-id="7237f52" data-element_type="widget"
                                data-settings='{"layout":"vertical","submenu_icon":{"value":"&lt;i class=\"fas fa-caret-down\"&gt;&lt;\/i&gt;","library":"fa-solid"}}'
                                data-widget_type="nav-menu.default">
                                <div class="elementor-widget-container">
                                    <nav aria-label="Menu"
                                        class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-vertical e--pointer-text e--animation-float">
                                        <ul id="menu-1-7237f52" class="elementor-nav-menu sm-vertical">
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-33614">
                                                <a href="https://www.pgsoftgaming.com/" class="elementor-item">首页</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2091">
                                                <a href="https://www.pgsoftgaming.com/pggame/"
                                                    class="elementor-item">游戏试玩</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26894">
                                                <a href="https://www.pgsoftgaming.com/brand/"
                                                    class="elementor-item">品牌介绍</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143">
                                                <a href="https://www.pgsoftgaming.com/official/"
                                                    class="elementor-item">官方活动</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26898">
                                                <a href="https://www.pgsoftgaming.com/partner/"
                                                    class="elementor-item">合作伙伴</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26938">
                                                <a href="https://www.pgsoftgaming.com/pg-news/"
                                                    class="elementor-item">动态消息</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2089">
                                                <a href="https://www.pgsoftgaming.com/download/"
                                                    class="elementor-item">PG APP下载</a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <nav class="elementor-nav-menu--dropdown elementor-nav-menu__container"
                                        aria-hidden="true">
                                        <ul id="menu-2-7237f52" class="elementor-nav-menu sm-vertical">
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-33614">
                                                <a href="https://www.pgsoftgaming.com/" class="elementor-item"
                                                    tabindex="-1">首页</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2091">
                                                <a href="https://www.pgsoftgaming.com/pggame/" class="elementor-item"
                                                    tabindex="-1">游戏试玩</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26894">
                                                <a href="https://www.pgsoftgaming.com/brand/" class="elementor-item"
                                                    tabindex="-1">品牌介绍</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143">
                                                <a href="https://www.pgsoftgaming.com/official/" class="elementor-item"
                                                    tabindex="-1">官方活动</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26898">
                                                <a href="https://www.pgsoftgaming.com/partner/" class="elementor-item"
                                                    tabindex="-1">合作伙伴</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26938">
                                                <a href="https://www.pgsoftgaming.com/pg-news/" class="elementor-item"
                                                    tabindex="-1">动态消息</a>
                                            </li>
                                            <li
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2089">
                                                <a href="https://www.pgsoftgaming.com/download/" class="elementor-item"
                                                    tabindex="-1">PG APP下载</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2ef4bc6 elementor-widget elementor-widget-shortcode"
                                data-id="2ef4bc6" data-element_type="widget" data-widget_type="shortcode.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-shortcode">
                                        <div data-elementor-type="section" data-elementor-id="33905"
                                            class="elementor elementor-33905 elementor-location-popup"
                                            data-elementor-post-type="elementor_library">
                                            <section
                                                class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                                data-id="2547e90" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00"
                                                        data-id="b9c4a00" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button"
                                                                data-id="ea97123" data-element_type="widget"
                                                                data-widget_type="button.default">
                                                                <div class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow"
                                                                            href="https://e88553.com/qbgyh7"
                                                                            target="_blank" rel="nofollow">
                                                                            <span
                                                                                class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true"
                                                                                        class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">2025
                                                                                    抢百万蛇我其谁</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div data-elementor-type="popup" data-elementor-id="4503"
            class="elementor elementor-4503 elementor-location-popup"
            data-elementor-settings='{"a11y_navigation":"yes","triggers":[],"timing":[]}'
            data-elementor-post-type="elementor_library">
            <section
                class="elementor-section elementor-top-section elementor-element elementor-element-9ecd11d elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                data-id="9ecd11d" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2b841ce"
                        data-id="2b841ce" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-fd352fa elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                data-id="fd352fa" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cea96ea"
                                        data-id="cea96ea" data-element_type="column"
                                        data-settings='{"background_background":"classic"}'>
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-5d84b8d elementor-widget elementor-widget-image"
                                                data-id="5d84b8d" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="http://e5557.com" target="_blank" rel="nofollow"
                                                        nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="265" height="120"
                                                            src="https://www.pgsoftgaming.com/wp-content/uploads/默认标题__2023-03-0314_15_17.png"
                                                            class="attachment-large size-large wp-image-3282"
                                                            alt="ESB LOGO" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f56822c"
                                        data-id="f56822c" data-element_type="column"
                                        data-settings='{"background_background":"classic"}'>
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-53ee41f elementor-widget elementor-widget-image"
                                                data-id="53ee41f" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="http://e5557.com" target="_blank" rel="nofollow"
                                                        nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="1280" height="420"
                                                            src="https://www.pgsoftgaming.com/wp-content/uploads/0.jpg"
                                                            class="attachment-large size-large wp-image-25169"
                                                            alt="E世博新年活动-发百万红包" srcset="
                                https://www.pgsoftgaming.com/wp-content/uploads/0.jpg         1280w,
                                https://www.pgsoftgaming.com/wp-content/uploads/0-768x252.jpg  768w
                              " sizes="(max-width: 1280px) 100vw, 1280px" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-e68390e elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                data-id="e68390e" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cc2c8ef"
                                        data-id="cc2c8ef" data-element_type="column"
                                        data-settings='{"background_background":"classic"}'>
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-9be0956 elementor-widget elementor-widget-image"
                                                data-id="9be0956" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="https://j935.com/?aff=1364796" target="_blank"
                                                        rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="265" height="120"
                                                            src="https://www.pgsoftgaming.com/wp-content/uploads/默认标题__2023-03-0414_39_01.png"
                                                            class="attachment-large size-large wp-image-3295"
                                                            alt="金博乐logo" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f048474"
                                        data-id="f048474" data-element_type="column"
                                        data-settings='{"background_background":"classic"}'>
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-c06af75 elementor-widget elementor-widget-image"
                                                data-id="c06af75" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="https://j935.com/?aff=1364796" target="_blank"
                                                        rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="970" height="184"
                                                            src="https://www.pgsoftgaming.com/wp-content/uploads/170706942063.jpg"
                                                            class="attachment-large size-large wp-image-25251" alt=""
                                                            srcset="
                                https://www.pgsoftgaming.com/wp-content/uploads/170706942063.jpg         970w,
                                https://www.pgsoftgaming.com/wp-content/uploads/170706942063-768x146.jpg 768w
                              " sizes="(max-width: 970px) 100vw, 970px" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section
                                class="elementor-section elementor-inner-section elementor-element elementor-element-3624fad elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                data-id="3624fad" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-11596c9"
                                        data-id="11596c9" data-element_type="column"
                                        data-settings='{"background_background":"classic"}'>
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-19dd667 elementor-widget elementor-widget-image"
                                                data-id="19dd667" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="https://ende.ga88s.news/AH503" target="_blank"
                                                        rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="265" height="120"
                                                            src="https://www.pgsoftgaming.com/wp-content/uploads/GA黃金甲.webp"
                                                            class="attachment-large size-large wp-image-3903"
                                                            alt="GA黃金甲" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ee6941c"
                                        data-id="ee6941c" data-element_type="column"
                                        data-settings='{"background_background":"classic"}'>
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-93670cc elementor-widget elementor-widget-image"
                                                data-id="93670cc" data-element_type="widget"
                                                data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="https://ende.ga88s.news/AH503" target="_blank"
                                                        rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="1420" height="544"
                                                            src="https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1.webp"
                                                            class="attachment-large size-large wp-image-25250" alt=""
                                                            srcset="
                                https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1.webp         1420w,
                                https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1-768x294.webp  768w
                              " sizes="(max-width: 1420px) 100vw, 1420px" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";

// 监听 DOMContentLoaded 事件，确保在 DOM 加载完成后执行代码
document.addEventListener('DOMContentLoaded', () => {
    // 获取所有包含 'elementor-post__thumbnail' 类名的元素
    const elements = document.querySelectorAll('.elementor-post__thumbnail');

    // 遍历并移除类名
    elements.forEach((element) => {
        element.classList.remove('elementor-post__thumbnail');
    });

    console.log('所有元素的 "elementor-post__thumbnail" 类名已被移除');
});
var ElementorProFrontendConfig = {
        ajaxurl: "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
        nonce: "92fd5d62e9",
        urls: {
          assets:
            "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/assets\/",
          rest: "https:\/\/www.pgsoftgaming.com\/wp-json\/",
        },
        settings: { lazy_load_background_images: true },
        popup: { hasPopUps: true },
        shareButtonsNetworks: {
          facebook: { title: "Facebook", has_counter: true },
          twitter: { title: "Twitter" },
          linkedin: { title: "LinkedIn", has_counter: true },
          pinterest: { title: "Pinterest", has_counter: true },
          reddit: { title: "Reddit", has_counter: true },
          vk: { title: "VK", has_counter: true },
          odnoklassniki: { title: "OK", has_counter: true },
          tumblr: { title: "Tumblr" },
          digg: { title: "Digg" },
          skype: { title: "Skype" },
          stumbleupon: { title: "StumbleUpon", has_counter: true },
          mix: { title: "Mix" },
          telegram: { title: "Telegram" },
          pocket: { title: "Pocket", has_counter: true },
          xing: { title: "XING", has_counter: true },
          whatsapp: { title: "WhatsApp" },
          email: { title: "Email" },
          print: { title: "Print" },
          "x-twitter": { title: "X" },
          threads: { title: "Threads" },
        },
        facebook_sdk: { lang: "zh_CN", app_id: "" },
        lottie: {
          defaultAnimationUrl:
            "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/modules\/lottie\/assets\/animations\/default.json",
        },
};
var elementorFrontendConfig = {
        environmentMode: {
          edit: false,
          wpPreview: false,
          isScriptDebug: false,
        },
        i18n: {
          shareOnFacebook: "\u5728\u8138\u4e66\u4e0a\u5206\u4eab",
          shareOnTwitter: "\u5206\u4eab\u5230Twitter",
          pinIt: "\u9489\u4f4f",
          download: "\u4e0b\u8f7d",
          downloadImage: "\u4e0b\u8f7d\u56fe\u7247",
          fullscreen: "\u5168\u5c4f",
          zoom: "\u7f29\u653e",
          share: "\u5206\u4eab",
          playVideo: "\u64ad\u653e\u89c6\u9891",
          previous: "\u4e0a\u4e00\u9875",
          next: "\u4e0b\u4e00\u9875",
          close: "\u5173\u95ed",
          a11yCarouselPrevSlideMessage: "\u4e0a\u4e00\u5f20\u5e7b\u706f\u7247",
          a11yCarouselNextSlideMessage: "\u4e0b\u4e00\u5f20\u5e7b\u706f\u7247",
          a11yCarouselFirstSlideMessage:
            "\u8fd9\u662f\u7b2c\u4e00\u5f20\u5e7b\u706f\u7247",
          a11yCarouselLastSlideMessage:
            "\u8fd9\u662f\u6700\u540e\u4e00\u5f20\u5e7b\u706f\u7247",
          a11yCarouselPaginationBulletMessage: "\u8f6c\u5230\u5e7b\u706f\u7247",
        },
        is_rtl: false,
        breakpoints: { xs: 0, sm: 480, md: 768, lg: 1025, xl: 1440, xxl: 1600 },
        responsive: {
          breakpoints: {
            mobile: {
              label: "\u624b\u673a\u7eb5\u5411",
              value: 767,
              default_value: 767,
              direction: "max",
              is_enabled: true,
            },
            mobile_extra: {
              label: "\u624b\u673a\u6a2a\u5411",
              value: 880,
              default_value: 880,
              direction: "max",
              is_enabled: false,
            },
            tablet: {
              label: "\u5e73\u677f\u7535\u8111\u7eb5\u5411",
              value: 1024,
              default_value: 1024,
              direction: "max",
              is_enabled: true,
            },
            tablet_extra: {
              label: "\u5e73\u677f\u7535\u8111\u6a2a\u5411",
              value: 1200,
              default_value: 1200,
              direction: "max",
              is_enabled: false,
            },
            laptop: {
              label: "\u7b14\u8bb0\u672c\u7535\u8111",
              value: 1366,
              default_value: 1366,
              direction: "max",
              is_enabled: false,
            },
            widescreen: {
              label: "\u5168\u5bbd\u5c4f",
              value: 2400,
              default_value: 2400,
              direction: "min",
              is_enabled: false,
            },
          },
          hasCustomBreakpoints: false,
        },
        version: "3.28.2",
        is_static: false,
        experimentalFeatures: {
          additional_custom_breakpoints: true,
          e_local_google_fonts: true,
          theme_builder_v2: true,
          "hello-theme-header-footer": true,
          editor_v2: true,
          home_screen: true,
        },
        urls: {
          assets:
            "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor\/assets\/",
          ajaxurl: "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
          uploadUrl: "http:\/\/www.pgsoftgaming.com\/wp-content\/uploads",
        },
        nonces: { floatingButtonsClickTracking: "eee63c18b4" },
        swiperClass: "swiper",
        settings: { editorPreferences: [] },
        kit: {
          body_background_background: "classic",
          active_breakpoints: ["viewport_mobile", "viewport_tablet"],
          global_image_lightbox: "yes",
          lightbox_enable_counter: "yes",
          lightbox_enable_fullscreen: "yes",
          lightbox_enable_zoom: "yes",
          lightbox_enable_share: "yes",
          lightbox_title_src: "title",
          lightbox_description_src: "description",
          hello_header_logo_type: "logo",
          hello_header_menu_layout: "horizontal",
          hello_footer_logo_type: "logo",
        },
        post: {
          id: 0,
          title:
            "\u79d1\u6280\u8d44\u8baf | PG\u7535\u5b50\u5b98\u65b9\u7f51\u7ad9",
          excerpt: "<p>\u79d1\u6280\u65b0\u95fb<\/p>\n",
        },
};
   
    

export default {
    data() {
        return {
            navParams: {
                pageNumber: 1,
                pageSize: 10,
                topicId: null
            },
            navList:[],
            newsList:[],
            totalRow:0,
            isloading:false,
            asiderList:[],
            shNewsList:[]

        }
    },
    mounted() {
        const scripts = [
            './js/webpack-pro.runtime.min.js',
            "./js/webpack.runtime.min.js",
            "./js/frontend-modules.min.js",
            "./js/frontend.min.js",
            "./js/frontend.mins.js",
            "./js/elements-handlers.min.js"


        ];
        this.loadScripts(scripts, () => {
            console.log('所有脚本加载完成');
            this.loadCSS('./css/post-31562.css', false)


        });

        let id = this.$route.query.id;
        if(id) {
            this.navParams.topicId = id;
            this.getNavList();
        }
        this.getAsiderList()

    },
    components: {
        Footer,
        Header
    },
    methods: {
        toNav(id) {
            if(id) {
                this.$router.push({
                    path: '/techNews',
                    query: {
                        id,
                       
                    }
                });
            }
        },
        //获取侧边栏数据
        async getAsiderList() {
            try {
                let res = await this.req.post('/article/rightSidebar', {});
                this.asiderList = res.data;
                this.shNewsList = res.data[res.data.length-1].articleEntities;
            } catch (error) {
                
            }
        },
        selectTab(id) {
            if(id) {
                this.$router.push({
                    path: '/newsDetail',
                    query: {
                        id,
                       
                    }
                });
            }
        },
        
        //获取分类的数据
        async getNavList() {
            try {
                let res = await this.req.post('/article/list', this.navParams);
                this.navList = res.data.list;
                this.totalRow = res.data.totalRow;
                // this.smallList = this.smallList.splice(0,6)
            } catch (error) {
                
            }
        },
        currentChange(val) {
            this.navParams.pageNumber = val;
            this.getNavList()
        },
        loadCSS(url, isCache) {
            const element = document.createElement("link");
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            if (isCache) {
                element.setAttribute("href", url + "?t=" + new Date().getTime());
            } else {
                element.setAttribute("href", url);
            }
            document.head.appendChild(element);
        },
        loadScripts(scripts, callback) {
            const loadNext = (index) => {
                if (index >= scripts.length) {
                    callback(); // 所有脚本加载完成后执行回调
                    return;
                }
                const script = document.createElement('script');
                script.src = scripts[index];
                script.onload = () => loadNext(index + 1); // 加载下一个脚本
                script.onerror = () => console.error(`Failed to load script: ${scripts[index]}`);
                document.body.appendChild(script);
            };
            loadNext(0); // 开始加载第一个脚本
        }
    }
}
</script>

<style scoped lang="less">
@import './css/frontend.min.css';
@import "./css/post-5.css";
@import "./css/widget-text-editor.min.css";
@import "./css/widget-spacer.min.css";
@import "./css/fadeInUp.min.css";
@import "./css/widget-posts.min.css";
@import "./css/post-5179.css";



#app {
    //  background: #230046;

    #content {
        margin-top: -80px;
    }
}

.elementor-post__thumbnail {
    padding-bottom: calc(0.6* 100%) !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    overflow: hidden !important;
}
.elementor-post__thumbnail__link {
    margin-bottom: -76px !important;
}
.elementor-section {
    // margin-bottom: 10px !important;
}
</style>
