/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2025-02-16 15:08:16
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2025-02-18 14:15:52
 * @FilePath: \sw_project\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'; //引入vue js是一个vue的配置文件 vue版本是2.6
import { default as Router, default as VueRouter } from 'vue-router'; //vue-router 安装的对象 npm install vue-router@"3.0.1"
import BrandIntroduction from "../brandIntroduction/brandIntroduction.vue";
import CooperativePartner from "../cooperativePartner/cooperativePartner.vue";
import Index from "../index"; //组件 components
import XboxOne from "../xboxOne/xboxOne.vue";
import OfficialActivities from "../officialActivities/officialActivities.vue";
import News from "../news/news.vue";
import NewsDetail from '../newsDetail/newsDetail.vue';
import TechNews from '../techNews/techNews.vue'
//vue中使用Router
Vue.use(Router)
const routers =[ //配置router与组件之间的对应关系
    {
        path: '/',
        component: Index,
        name: 'index',
    },
    {
        path: '/brandIntroduction',
        component: BrandIntroduction,
        name: 'brandIntroduction',
    },
    {
        path: '/cooperativePartner',
        component: CooperativePartner,
        name: 'cooperativePartner',
    },
    {
        path: '/xboxOne',
        component: XboxOne,
        name: 'xboxOne',
    },
    {
         path: '/officialActivities',
         component: OfficialActivities,
         name: 'officialActivities',
    },
    {
            path: '/news',
            component: News,
            name: 'newsBox',
    },
    {
        path: '/newsDetail',
        component: NewsDetail,
        name: 'newsDetailBox',
    },
    {
        path: '/techNews',
        component: TechNews,
        name: 'techNewsBox',
    }
]
//创建一个vue-router实例对象
const router =new VueRouter({
    mode:"history",//history 不带#号 hash 带#号
    routes:routers //所有的路由数组
})
//导出路由对象  使用路由的地方引入即可  一般在入口main.js中使用
export default router;
