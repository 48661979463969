<template>
    
    <div id="app" class="page-template-default page page-id-26917 wp-custom-logo unselectable elementor-default elementor-kit-5 elementor-page elementor-page-26917">
        <Header ></Header>
        <main style="padding-top: 80px;box-sizing: border-box;" id="content" class="site-main post-26917 page type-page status-publish hentry">
            <div class="page-content">
                <div data-elementor-type="wp-page" data-elementor-id="26917" class="elementor elementor-26917" data-elementor-post-type="page">
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-ce95e99 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="ce95e99" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-48824b0" data-id="48824b0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-28a70f5 elementor-nav-menu--dropdown-none elementor-widget__width-initial elementor-widget elementor-widget-nav-menu" data-id="28a70f5" data-element_type="widget" data-settings="{&quot;layout&quot;:&quot;horizontal&quot;,&quot;submenu_icon&quot;:{&quot;value&quot;:&quot;&lt;i class=\&quot;fas fa-caret-down\&quot;&gt;&lt;\/i&gt;&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="nav-menu.default">
                                        <div class="elementor-widget-container">
                                            <nav aria-label="Menu" class="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-underline e--animation-fade">
                                                <ul id="menu-1-28a70f5" class="elementor-nav-menu">
                                                    <li @click.prevent="toNav(item.id)" v-for="(item,index) in navList" :key="index" class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-26923">
                                                        <a href="https://www.pgsoftgaming.com/category/tech-news/" class="elementor-item">{{ item.topicName }}</a>
                                                    </li>
                                                   
                                                </ul>
                                            </nav>
                                            <nav class="elementor-nav-menu--dropdown elementor-nav-menu__container" aria-hidden="true">
                                                <ul id="menu-2-28a70f5" class="elementor-nav-menu">
                                                    <li @click.prevent="toNav(initem.id)" v-for="(initem,inindex) in navList" :key="inindex" class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-26923">
                                                        <a href="https://www.pgsoftgaming.com/category/tech-news/" class="elementor-item" tabindex="-1">{{ initem.topicName }}</a>
                                                    </li>
                                                    
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-23a2a90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="23a2a90" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-cf74b8c" data-id="cf74b8c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-5989e76 elementor-grid-1 elementor-posts--thumbnail-left load-more-align-justify elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-widget elementor-widget-posts" data-id="5989e76" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;1&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;pagination_type&quot;:&quot;load_more_on_click&quot;,&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;1&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;load_more_spinner&quot;:{&quot;value&quot;:&quot;fas fa-spinner&quot;,&quot;library&quot;:&quot;fa-solid&quot;}}" data-widget_type="posts.classic">
                                        <div class="elementor-widget-container">
                                            <div style="cursor: pointer;" class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                <article @click.prevent="selectTab(newItem.id)" v-for="(newItem,newIndex) in newsList" :key="newIndex" class="elementor-post elementor-grid-item post-33231 post type-post status-publish format-standard has-post-thumbnail hentry category-china-news">
                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/china-news/2024/09/33231/" tabindex="-1">
                                                        <div class="elementor-post__thumbnail">
                                                            <img style="object-fit: cover;object-position: center;height: 120px;" fetchpriority="high" decoding="async" width="600" height="900" :src="$imgUrl + newItem.titlePic" class="attachment-medium size-medium wp-image-33224" alt=""/>
                                                        </div>
                                                    </a>
                                                    <div class="elementor-post__text">
                                                        <h3 class="elementor-post__title">
                                                           <span>{{ newItem.title }}</span>
                                                        </h3>
                                                        <div class="elementor-post__meta-data">
                                                            <span class="elementor-post-date">{{ $formatDate(newItem.updateTime) }}		</span>
                                                        </div>
                                                        <div class="elementor-post__excerpt">
                                                            <p>{{ newItem.description }}</p>
                                                        </div>
                                                    </div>
                                                </article>
                                                
                                            </div>
                                            <!-- <span class="e-load-more-spinner">
                                                <i aria-hidden="true" class="fas fa-spinner"></i>
                                            </span> -->
                                            <!-- <div class="e-load-more-anchor" data-page="1" data-max-page="508" ></div> -->
                                            <div class="elementor-button-wrapper">
                                                <a @click.prevent="loadMoveHandle" href="#" class="elementor-button-link elementor-button" role="button">
                                                    <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-icon">
                                                            <i aria-hidden="true" class="fas fa-arrow-circle-down"></i>
                                                        </span>
                                                        <span class="elementor-button-text">看更多</span>
                                                    </span>
                                                </a>
                                            </div>
                                            <div v-if="isloading" class="e-load-more-message"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-671febe" data-id="671febe" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-1031938 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1031938" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-fe9d385" data-id="fe9d385" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-782cd09 elementor-widget elementor-widget-shortcode" data-id="782cd09" data-element_type="widget" data-widget_type="shortcode.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-shortcode">
                                                                <div data-elementor-type="section" data-elementor-id="31562" class="elementor elementor-31562" data-elementor-post-type="elementor_library">
                                                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-4313ba0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4313ba0" data-element_type="section">
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-47dc7b6" data-id="47dc7b6" data-element_type="column">
                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                    <section   v-for="(asiderItem,asiderIndex) in asiderList" :key="asiderIndex" class="elementor-section elementor-inner-section elementor-element elementor-element-6eee4eb elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6eee4eb" data-element_type="section">
                                                                                        <div v-if="asiderItem.topicName!='社会新闻'" class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2cd72dd" data-id="2cd72dd" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-b78bd3e elementor-widget elementor-widget-heading" data-id="b78bd3e" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">{{ asiderItem.topicName }}</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-5374539 elementor-grid-2 elementor-grid-mobile-2 elementor-grid-tablet-2 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts" data-id="5374539" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;2&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article @click.prevent="selectTab(articleItem.id)" v-for="(articleItem,articleIndex) in asiderItem.articleEntities" :key="articleIndex" class="elementor-post elementor-grid-item post-32469 post type-post status-publish format-standard has-post-thumbnail hentry category-games">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/games/2024/07/32469/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;"  loading="lazy" decoding="async" width="500" height="149" :src="$imgUrl + articleItem.titlePic" class="attachment-medium size-medium wp-image-32467" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/games/2024/07/32469/">{{ articleItem.title }}</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                    <!-- <section class="elementor-section elementor-inner-section elementor-element elementor-element-fd01a28 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="fd01a28" data-element_type="section">
                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f73aad7" data-id="f73aad7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-6d58b2c elementor-widget elementor-widget-heading" data-id="6d58b2c" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">体育专栏</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-a050e87 elementor-grid-2 elementor-grid-mobile-2 elementor-grid-tablet-2 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts" data-id="a050e87" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;2&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article class="elementor-post elementor-grid-item post-32470 post type-post status-publish format-standard has-post-thumbnail hentry category-tiyu">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/tiyu/2024/07/32470/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;" loading="lazy" decoding="async" width="660" height="384" src="../assets/news/2024071900_激动人心的体育投注讲解：46岁阿根廷少帅3年4连冠传奇_.jpg" class="attachment-medium size-medium wp-image-32468" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/tiyu/2024/07/32470/">激动人心的体育投注讲解：46岁阿根廷少帅3年4连冠传奇			</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                <article class="elementor-post elementor-grid-item post-32435 post type-post status-publish format-standard has-post-thumbnail hentry category-tiyu">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/tiyu/2024/07/32435/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;" loading="lazy" decoding="async" width="799" height="532" src="../assets/news/2024071800_2024欧洲国家盃最终决赛：西班牙vs英格兰_体育下注全攻略_.jpg" class="attachment-medium size-medium wp-image-32434" alt=""  sizes="(max-width: 799px) 100vw, 799px"/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/tiyu/2024/07/32435/">2024欧洲国家盃最终决赛：西班牙vs英格兰 体育下注全攻略			</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section> -->
                                                                                    <section style="margin-top: -10px;" class="elementor-section elementor-inner-section elementor-element elementor-element-90f5ed1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="90f5ed1" data-element_type="section">
                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-fcd30b3" data-id="fcd30b3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-e8be210 elementor-widget elementor-widget-heading" data-id="e8be210" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">热门焦点</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-a2b8db6 elementor-grid-1 elementor-posts--thumbnail-left elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-widget elementor-widget-posts" data-id="a2b8db6" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;1&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;1&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article @click.prevent="selectTab(shItem.id)" v-for="(shItem,shIndex) in shNewsList" :key="shIndex" class="elementor-post elementor-grid-item post-33231 post type-post status-publish format-standard has-post-thumbnail hentry category-china-news">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/china-news/2024/09/33231/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 70px;" fetchpriority="high" decoding="async" width="600" height="900" :src="$imgUrl + shItem.titlePic" class="attachment-medium size-medium wp-image-33224" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/china-news/2024/09/33231/">{{  shItem.title }}</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </main>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";
import { Message } from 'element-ui'; // 引入 Element UI 的消息提示组件

// 监听 DOMContentLoaded 事件，确保在 DOM 加载完成后执行代码
document.addEventListener('DOMContentLoaded', () => {
    // 获取所有包含 'elementor-post__thumbnail' 类名的元素
    const elements = document.querySelectorAll('.elementor-post__thumbnail');

    // 遍历并移除类名
    elements.forEach((element) => {
        element.classList.remove('elementor-post__thumbnail');
    });

    console.log('所有元素的 "elementor-post__thumbnail" 类名已被移除');
});

export default {
    data() {
        return {
            navList:[],
            newsPramas: {
                pageNumber:1,
                pageSize:10,
                topicId:null
            },
            newsList:[],
            totalRow:0,
            isloading:false,
            asiderList:[],
            shNewsList:[]
        }
    },
    mounted() {
        const scripts = [
            // './js/webpack-pro.runtime.min.js',
            // "./js/webpack.runtime.min.js",
            // "./js/frontend-modules.min.js",
            "./js/frontend.min.js",
            // "./js/frontend.mins.js",
            // "./js/elements-handlers.min.js",
            // "./js/jquery.min.js"
           
        ];
        this.loadScripts(scripts, () => {
            console.log('所有脚本加载完成');
            this.loadCSS('./css/post-31562.css',false)

            
        });
        this.getNav();
        this.getNewsList()
        this.getAsiderList()
    },
    components: {
        Footer,
        Header
    },
    methods: {
        toNav(id) {
            if(id) {
                this.$router.push({
                    path: '/techNews',
                    query: {
                        id,
                       
                    }
                });
            }
        },
        //获取侧边栏数据
        async getAsiderList() {
            try {
                let res = await this.req.post('/article/rightSidebar', {});
                this.asiderList = res.data;
                this.shNewsList = res.data[res.data.length-1].articleEntities;
            } catch (error) {
                
            }
        },
        selectTab(id) {
            if(id) {
                this.$router.push({
                    path: '/newsDetail',
                    query: {
                        id,
                       
                    }
                });
            }
        },
        loadMoveHandle() {
            if(this.newsPramas.pageNumber>Math.ceil(this.totalRow/this.newsPramas.pageSize)) {
                Message({
                    message: '没有更多数据' || 'Error',
                    type: 'waring',
                    duration: 5 * 1000,
                });
                return;
            }
            this.newsPramas.pageNumber++;
            this.getNewsList()
        },
        //获取列表数据
        async getNewsList() {
            try {
                this.isloading = true;
                let res = await this.req.post('/article/list', this.newsPramas);
                this.isloading = false;
                if(this.newsPramas.pageNumber == 1) {
                    this.newsList = res.data.list;
                }else {
                    this.newsList =[...this.newsList, ...res.data.list];

                }
                this.totalRow = res.data.totalRow;
                console.log('ok',res.data)
            } catch (error) {
                this.isloading = false;
            }
        },
        //获取导航栏
        async getNav() {
            try {
                let res = await this.req.get('/topic/list/ARTICLE');
                console.log(111, res)
                this.navList = res.data;
            } catch (error) {
                
            }
        },
        loadCSS(url, isCache) {
            const element = document.createElement("link");
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            if (isCache) {
            element.setAttribute("href", url + "?t=" + new Date().getTime());
            } else {
            element.setAttribute("href", url);
            }
            document.head.appendChild(element);
        },
        loadScripts(scripts, callback) {
            const loadNext = (index) => {
            if (index >= scripts.length) {
                callback(); // 所有脚本加载完成后执行回调
                return;
            }
            const script = document.createElement('script');
            script.src = scripts[index];
            script.onload = () => loadNext(index + 1); // 加载下一个脚本
            script.onerror = () => console.error(`Failed to load script: ${scripts[index]}`);
            document.body.appendChild(script);
            };
            loadNext(0); // 开始加载第一个脚本
        }
    }
}
</script>

<style scoped lang="less">
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.28.1';
@import "./css/frontend.min.css";
// @import "https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-5.css?ver=1734676771";
@import "./css/post-5.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/widget-nav-menu.min.css?ver=3.28.1';
@import "./css/widget-nav-menu.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/widget-posts.min.css?ver=3.28.1';
@import './css/widget-posts.min.css';
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-26917.css?ver=1734677848';
@import './css/post-26917.css';
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-261.css?ver=1734676771';
@import './css/post-261.css';
@import './css/post-31562.css';

#app {
//  background: #230046;

#content {
    margin-top: -80px;
}
}

.elementor-post__thumbnail {
    padding-bottom: calc(0.6* 100%) !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    overflow: hidden !important;
}

.elementor-post__thumbnail__link {
    margin-bottom: -76px !important;
}
.elementor-section {
    margin-bottom: 10px !important;
}
</style>
