<template>
    <div id="app" class="post-template-default single single-post postid-33231 single-format-standard wp-custom-logo unselectable elementor-default elementor-kit-5 elementor-page-4426">
        <Header></Header>
        <div data-elementor-type="single-post" data-elementor-id="4426" class="elementor elementor-4426 elementor-location-single post-33231 post type-post status-publish format-standard has-post-thumbnail hentry category-china-news" data-elementor-post-type="elementor_library">
                <section class="elementor-section elementor-top-section elementor-element elementor-element-2a45744 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2a45744" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                    <div class="elementor-container elementor-column-gap-default">
                        <div class="elementor-column elementor-col-66 elementor-top-column elementor-element elementor-element-a5f2aed" data-id="a5f2aed" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                            <div class="elementor-widget-wrap elementor-element-populated">
                                <div class="elementor-element elementor-element-7dd4e3e elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-text-editor" data-id="7dd4e3e" data-element_type="widget" data-widget_type="text-editor.default">
                                    <div class="elementor-widget-container">
                                        <p>
                                            <a @click.prevent="$router.push('/')" href="https://www.pgsoftgaming.com/official/">首页 &gt;</a>
                                        </p>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-5047851 elementor-widget__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-post-info" data-id="5047851" data-element_type="widget" data-widget_type="post-info.default">
                                    <div class="elementor-widget-container">
                                        <ul class="elementor-icon-list-items elementor-post-info">
                                            <li  @click.prevent="toNav(detialData.topicId)" class="elementor-icon-list-item elementor-repeater-item-39ed336" itemprop="about">
                                                <span class="elementor-icon-list-text elementor-post-info__item elementor-post-info__item--type-terms">
                                                    <span class="elementor-post-info__terms-list">
                                                        <a href="https://www.pgsoftgaming.com/category/china-news/" class="elementor-post-info__terms-list-item">{{ detialData.topicName }}</a>
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-0e9e904 elementor-widget elementor-widget-theme-post-title elementor-page-title elementor-widget-heading" data-id="0e9e904" data-element_type="widget" data-widget_type="theme-post-title.default">
                                    <div class="elementor-widget-container">
                                        <h1 class="elementor-heading-title elementor-size-default">{{ detialData.title }}</h1>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-5c2ae20 elementor-widget elementor-widget-post-info" data-id="5c2ae20" data-element_type="widget" data-widget_type="post-info.default">
                                    <div class="elementor-widget-container">
                                        <ul class="elementor-inline-items elementor-icon-list-items elementor-post-info">
                                            <li @click.prevent="toNav(detialData.topicId)" class="elementor-icon-list-item elementor-repeater-item-39ed336 elementor-inline-item" itemprop="about">
                                                <span class="elementor-icon-list-icon">
                                                    <i aria-hidden="true" class="fas fa-tags"></i>
                                                </span>
                                                <span class="elementor-icon-list-text elementor-post-info__item elementor-post-info__item--type-terms">
                                                    <span class="elementor-post-info__terms-list">
                                                        <a href="https://www.pgsoftgaming.com/category/china-news/" class="elementor-post-info__terms-list-item">{{ detialData.topicName }}</a>
                                                    </span>
                                                </span>
                                            </li>
                                            <li class="elementor-icon-list-item elementor-repeater-item-786bc1d elementor-inline-item" itemprop="datePublished">
                                                <a href="#">
                                                    <span class="elementor-icon-list-icon">
                                                        <i aria-hidden="true" class="fas fa-calendar"></i>
                                                    </span>
                                                    <span class="elementor-icon-list-text elementor-post-info__item elementor-post-info__item--type-date">
                                                        <time>{{ $formatDateTime(detialData.createTime) }}</time>
                                                    </span>
                                                </a>
                                            </li>
                                            <li class="elementor-icon-list-item elementor-repeater-item-ac35908 elementor-inline-item">
                                                <span class="elementor-icon-list-icon">
                                                    <i aria-hidden="true" class="far fa-clock"></i>
                                                </span>
                                                <span class="elementor-icon-list-text elementor-post-info__item elementor-post-info__item--type-time">
                                                    <time>{{ $formatStrTime(detialData.createTime) }}</time>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-07377ca elementor-widget elementor-widget-theme-post-content" data-id="07377ca" data-element_type="widget" data-widget_type="theme-post-content.default">
                                    <div class="elementor-widget-container">
                                        <p>
                                            <!-- <img decoding="async" :src="detialData.titlePicView"> -->
                                            <br/>
                                            <div v-html="detialData.content"></div>
                                        </p>
                                        
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-f30d6b5 elementor-post-navigation-borders-yes elementor-widget elementor-widget-post-navigation" data-id="f30d6b5" data-element_type="widget" data-widget_type="post-navigation.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-post-navigation" style="display: flex;align-items: center;">
                                            <div v-if="detialData.previous" @click.prevent="selectTab(detialData.previous.id)" class="elementor-post-navigation__prev elementor-post-navigation__link">
                                                <a href="https://www.pgsoftgaming.com/china-news/2024/09/33229/" rel="prev">
                                                    <span class="post-navigation__arrow-wrapper post-navigation__arrow-prev">
                                                        <i class="fa fa-angle-left" aria-hidden="true"></i><span class="elementor-screen-only">Prev</span>
                                                    </span>
                                                    <span class="elementor-post-navigation__link__prev"><span class="post-navigation__prev--label">阅读上一篇</span>
                                                    <span class="post-navigation__prev--title">{{ detialData.previous && detialData.previous.title }}</span>
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="elementor-post-navigation__separator-wrapper">
                                                <div class="elementor-post-navigation__separator"></div>
                                            </div>
                                            <div v-if="detialData.next" @click.prevent="selectTab(detialData.next.id)" class="elementor-post-navigation__next elementor-post-navigation__link">
                                                <a href="https://www.pgsoftgaming.com/china-news/2024/09/33231/" rel="next">
                                                    <span class="elementor-post-navigation__link__next">
                                                        <span class="post-navigation__next--label">阅读下一篇</span>
                                                        <span class="post-navigation__next--title">{{ detialData.next && detialData.next.title }}</span>
                                                    </span>
                                                    <span class="post-navigation__arrow-wrapper post-navigation__arrow-next">
                                                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                                                        <span class="elementor-screen-only">Next</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <section class="elementor-section elementor-inner-section elementor-element elementor-element-1c1cc9d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1c1cc9d" data-element_type="section">
                                    <div class="elementor-container elementor-column-gap-default">
                                        <div @click.prevent="$router.push('/xboxOne')" class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-3859b3a" data-id="3859b3a" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-2dacc81 elementor-widget elementor-widget-image" data-id="2dacc81" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <a href="#">
                                                            <img width="186" height="186" src="../assets/officialActivities/AnyConv.com__app_icon_small_3x.webp" class="attachment-large size-large wp-image-101" alt=""  sizes="(max-width: 186px) 100vw, 186px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-d0ddfd8 elementor-widget elementor-widget-text-editor" data-id="d0ddfd8" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            <a href="">麻将胡了</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div @click.prevent="$router.push('/xboxOne')" class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4eff5d6" data-id="4eff5d6" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-1555e51 elementor-widget elementor-widget-image" data-id="1555e51" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <a href="#">
                                                            <img width="186" height="186" src="../assets/officialActivities/AnyConv.com__麻将方式-2.webp" class="attachment-large size-large wp-image-112" alt=""  sizes="(max-width: 186px) 100vw, 186px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-a2335df elementor-widget elementor-widget-text-editor" data-id="a2335df" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            <a href="#">麻将胡了2</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div @click.prevent="$router.push('/xboxOne')" class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-468295e" data-id="468295e" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-c10bc04 elementor-widget elementor-widget-image" data-id="c10bc04" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <a href="#">
                                                            <img loading="lazy" width="186" height="186" src="../assets/officialActivities/AnyConv.com__app_icon_small_3x-39.webp" class="attachment-large size-large wp-image-73" alt="AnyConv.com_赏金女王"  sizes="(max-width: 186px) 100vw, 186px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-643175c elementor-widget elementor-widget-text-editor" data-id="643175c" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            <a href="#">赏金女王</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div @click.prevent="$router.push('/xboxOne')" class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-b6f6d22" data-id="b6f6d22" data-element_type="column">
                                            <div class="elementor-widget-wrap elementor-element-populated">
                                                <div class="elementor-element elementor-element-2bdde9f elementor-widget elementor-widget-image" data-id="2bdde9f" data-element_type="widget" data-widget_type="image.default">
                                                    <div class="elementor-widget-container">
                                                        <a href="#">
                                                            <img loading="lazy" width="186" height="186" src="../assets/officialActivities/AnyConv.com__app_icon_small_3x-14.webp" class="attachment-large size-large wp-image-48" alt="AnyConv.com_发财鱼虾蟹"  sizes="(max-width: 186px) 100vw, 186px"/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="elementor-element elementor-element-c2f548b elementor-widget elementor-widget-text-editor" data-id="c2f548b" data-element_type="widget" data-widget_type="text-editor.default">
                                                    <div class="elementor-widget-container">
                                                        <p>
                                                            <a href="#">发财鱼虾蟹</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div class="elementor-element elementor-element-e9635c0 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="e9635c0" data-element_type="widget" data-widget_type="divider.default">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-divider">
                                            <span class="elementor-divider-separator"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-8c8f4d4 elementor-widget elementor-widget-heading" data-id="8c8f4d4" data-element_type="widget" data-widget_type="heading.default">
                                    <div class="elementor-widget-container">
                                        <h4 class="elementor-heading-title elementor-size-default">相关新闻</h4>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-faf2e93 elementor-grid-1 elementor-grid-tablet-1 elementor-posts--thumbnail-left elementor-grid-mobile-1 elementor-widget elementor-widget-posts" data-id="faf2e93" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;1&quot;,&quot;classic_columns_tablet&quot;:&quot;1&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]},&quot;classic_columns_mobile&quot;:&quot;1&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                    <div class="elementor-widget-container">
                                        <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                            <article @click.prevent="selectTab(smallItem.id)" v-for="(smallItem,smallIndex) in smallList" :Key="smallIndex" class="elementor-post elementor-grid-item post-33231 post type-post status-publish format-standard has-post-thumbnail hentry category-china-news">
                                                <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/china-news/2024/09/33231/" tabindex="-1">
                                                    <div class="elementor-post__thumbnail">
                                                        <img style="object-fit: cover;object-position: center;height: 77px;" loading="lazy" width="600" height="900" :src="$imgUrl+smallItem.titlePic" class="attachment-medium size-medium wp-image-33224" alt=""/>
                                                    </div>
                                                </a>
                                                <div class="elementor-post__text">
                                                    <h3 class="elementor-post__title">
                                                        <a href="#">{{ smallItem.title }}</a>
                                                    </h3>
                                                    <div class="elementor-post__meta-data">
                                                        <span class="elementor-post-date">{{ $formatDate(smallItem.updateTime) }}		</span>
                                                    </div>
                                                    <div class="elementor-post__excerpt">
                                                        <p>{{ smallItem.description }}</p>
                                                    </div>
                                                </div>
                                            </article>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-671febe" data-id="671febe" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-1031938 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1031938" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-fe9d385" data-id="fe9d385" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-782cd09 elementor-widget elementor-widget-shortcode" data-id="782cd09" data-element_type="widget" data-widget_type="shortcode.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-shortcode">
                                                                <div data-elementor-type="section" data-elementor-id="31562" class="elementor elementor-31562" data-elementor-post-type="elementor_library">
                                                                    <section class="elementor-section elementor-top-section elementor-element elementor-element-4313ba0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4313ba0" data-element_type="section">
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-47dc7b6" data-id="47dc7b6" data-element_type="column">
                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                    <section   v-for="(asiderItem,asiderIndex) in asiderList" :key="asiderIndex" class="elementor-section elementor-inner-section elementor-element elementor-element-6eee4eb elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6eee4eb" data-element_type="section">
                                                                                        <div v-if="asiderItem.topicName!='社会新闻'" class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-2cd72dd" data-id="2cd72dd" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-b78bd3e elementor-widget elementor-widget-heading" data-id="b78bd3e" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">{{ asiderItem.topicName }}</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-5374539 elementor-grid-2 elementor-grid-mobile-2 elementor-grid-tablet-2 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts" data-id="5374539" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;2&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article @click.prevent="selectTab(articleItem.id)" v-for="(articleItem,articleIndex) in asiderItem.articleEntities" :key="articleIndex" class="elementor-post elementor-grid-item post-32469 post type-post status-publish format-standard has-post-thumbnail hentry category-games">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/games/2024/07/32469/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;"  loading="lazy" decoding="async" width="500" height="149" :src="$imgUrl + articleItem.titlePic" class="attachment-medium size-medium wp-image-32467" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/games/2024/07/32469/">{{ articleItem.title }}</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                    <!-- <section class="elementor-section elementor-inner-section elementor-element elementor-element-fd01a28 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="fd01a28" data-element_type="section">
                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f73aad7" data-id="f73aad7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-6d58b2c elementor-widget elementor-widget-heading" data-id="6d58b2c" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">体育专栏</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-a050e87 elementor-grid-2 elementor-grid-mobile-2 elementor-grid-tablet-2 elementor-posts--thumbnail-top elementor-widget elementor-widget-posts" data-id="a050e87" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;2&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article class="elementor-post elementor-grid-item post-32470 post type-post status-publish format-standard has-post-thumbnail hentry category-tiyu">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/tiyu/2024/07/32470/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;" loading="lazy" decoding="async" width="660" height="384" src="../assets/news/2024071900_激动人心的体育投注讲解：46岁阿根廷少帅3年4连冠传奇_.jpg" class="attachment-medium size-medium wp-image-32468" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/tiyu/2024/07/32470/">激动人心的体育投注讲解：46岁阿根廷少帅3年4连冠传奇			</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                <article class="elementor-post elementor-grid-item post-32435 post type-post status-publish format-standard has-post-thumbnail hentry category-tiyu">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/tiyu/2024/07/32435/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 90px;" loading="lazy" decoding="async" width="799" height="532" src="../assets/news/2024071800_2024欧洲国家盃最终决赛：西班牙vs英格兰_体育下注全攻略_.jpg" class="attachment-medium size-medium wp-image-32434" alt=""  sizes="(max-width: 799px) 100vw, 799px"/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/tiyu/2024/07/32435/">2024欧洲国家盃最终决赛：西班牙vs英格兰 体育下注全攻略			</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section> -->
                                                                                    <section style="margin-top: -10px;" class="elementor-section elementor-inner-section elementor-element elementor-element-90f5ed1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="90f5ed1" data-element_type="section">
                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-fcd30b3" data-id="fcd30b3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                                                    <div class="elementor-element elementor-element-e8be210 elementor-widget elementor-widget-heading" data-id="e8be210" data-element_type="widget" data-widget_type="heading.default">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <h2 class="elementor-heading-title elementor-size-default">热门焦点</h2>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-element elementor-element-a2b8db6 elementor-grid-1 elementor-posts--thumbnail-left elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-widget elementor-widget-posts" data-id="a2b8db6" data-element_type="widget" data-settings="{&quot;classic_columns&quot;:&quot;1&quot;,&quot;classic_row_gap&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:12,&quot;sizes&quot;:[]},&quot;classic_columns_tablet&quot;:&quot;2&quot;,&quot;classic_columns_mobile&quot;:&quot;1&quot;,&quot;classic_row_gap_tablet&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]},&quot;classic_row_gap_mobile&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:[]}}" data-widget_type="posts.classic">
                                                                                                        <div class="elementor-widget-container">
                                                                                                            <div class="elementor-posts-container elementor-posts elementor-posts--skin-classic elementor-grid">
                                                                                                                <article @click.prevent="selectTab(shItem.id)" v-for="(shItem,shIndex) in shNewsList" :key="shIndex" class="elementor-post elementor-grid-item post-33231 post type-post status-publish format-standard has-post-thumbnail hentry category-china-news">
                                                                                                                    <a class="elementor-post__thumbnail__link" href="https://www.pgsoftgaming.com/china-news/2024/09/33231/" tabindex="-1">
                                                                                                                        <div class="elementor-post__thumbnail">
                                                                                                                            <img style="object-fit: cover;object-position: center;height: 70px;" fetchpriority="high" decoding="async" width="600" height="900" :src="$imgUrl + shItem.titlePic" class="attachment-medium size-medium wp-image-33224" alt=""/>
                                                                                                                        </div>
                                                                                                                    </a>
                                                                                                                    <div class="elementor-post__text">
                                                                                                                        <h3 class="elementor-post__title">
                                                                                                                            <a href="https://www.pgsoftgaming.com/china-news/2024/09/33231/">{{  shItem.title }}</a>
                                                                                                                        </h3>
                                                                                                                    </div>
                                                                                                                </article>
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                
                                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                    </div>
                </section>
        </div>
           
            
        <div data-elementor-type="popup" data-elementor-id="4503" class="elementor elementor-4503 elementor-location-popup" data-elementor-settings="{&quot;a11y_navigation&quot;:&quot;yes&quot;,&quot;triggers&quot;:[],&quot;timing&quot;:[]}" data-elementor-post-type="elementor_library">
            <section class="elementor-section elementor-top-section elementor-element elementor-element-9ecd11d elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="9ecd11d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2b841ce" data-id="2b841ce" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-fd352fa elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="fd352fa" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cea96ea" data-id="cea96ea" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-5d84b8d elementor-widget elementor-widget-image" data-id="5d84b8d" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="http://e5557.com" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="265" height="120" src="https://www.pgsoftgaming.com/wp-content/uploads/默认标题__2023-03-0314_15_17.png" class="attachment-large size-large wp-image-3282" alt="ESB LOGO"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f56822c" data-id="f56822c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-53ee41f elementor-widget elementor-widget-image" data-id="53ee41f" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="http://e5557.com" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="1280" height="420" src="https://www.pgsoftgaming.com/wp-content/uploads/0.jpg" class="attachment-large size-large wp-image-25169" alt="E世博新年活动-发百万红包" srcset="https://www.pgsoftgaming.com/wp-content/uploads/0.jpg 1280w, https://www.pgsoftgaming.com/wp-content/uploads/0-768x252.jpg 768w" sizes="(max-width: 1280px) 100vw, 1280px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-e68390e elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="e68390e" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-cc2c8ef" data-id="cc2c8ef" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-9be0956 elementor-widget elementor-widget-image" data-id="9be0956" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="https://j935.com/?aff=1364796" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="265" height="120" src="https://www.pgsoftgaming.com/wp-content/uploads/默认标题__2023-03-0414_39_01.png" class="attachment-large size-large wp-image-3295" alt="金博乐logo"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f048474" data-id="f048474" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-c06af75 elementor-widget elementor-widget-image" data-id="c06af75" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="https://j935.com/?aff=1364796" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="970" height="184" src="https://www.pgsoftgaming.com/wp-content/uploads/170706942063.jpg" class="attachment-large size-large wp-image-25251" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/170706942063.jpg 970w, https://www.pgsoftgaming.com/wp-content/uploads/170706942063-768x146.jpg 768w" sizes="(max-width: 970px) 100vw, 970px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <section class="elementor-section elementor-inner-section elementor-element elementor-element-3624fad elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="3624fad" data-element_type="section">
                                <div class="elementor-container elementor-column-gap-wide">
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-11596c9" data-id="11596c9" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-19dd667 elementor-widget elementor-widget-image" data-id="19dd667" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="https://ende.ga88s.news/AH503" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="265" height="120" src="https://www.pgsoftgaming.com/wp-content/uploads/GA黃金甲.webp" class="attachment-large size-large wp-image-3903" alt="GA黃金甲"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ee6941c" data-id="ee6941c" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                        <div class="elementor-widget-wrap elementor-element-populated">
                                            <div class="elementor-element elementor-element-93670cc elementor-widget elementor-widget-image" data-id="93670cc" data-element_type="widget" data-widget_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <a href="https://ende.ga88s.news/AH503" target="_blank" rel="nofollow" nofollow="" noreferrer="" noopener="">
                                                        <img loading="lazy" width="1420" height="544" src="https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1.webp" class="attachment-large size-large wp-image-25250" alt="" srcset="https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1.webp 1420w, https://www.pgsoftgaming.com/wp-content/uploads/j8w8wcgiaKtoVuiMNyOOpEmm4X94RJ4QoxpRDXRP-1-768x294.webp 768w" sizes="(max-width: 1420px) 100vw, 1420px"/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <Footer></Footer>
    </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";

// 监听 DOMContentLoaded 事件，确保在 DOM 加载完成后执行代码
document.addEventListener('DOMContentLoaded', () => {
    // 获取所有包含 'elementor-post__thumbnail' 类名的元素
    const elements = document.querySelectorAll('.elementor-post__thumbnail');

    // 遍历并移除类名
    elements.forEach((element) => {
        element.classList.remove('elementor-post__thumbnail');
    });

    console.log('所有元素的 "elementor-post__thumbnail" 类名已被移除');
});

var ElementorProFrontendConfig = {
                    "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
                    "nonce": "76e2a3e79d",
                    "urls": {
                        "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/assets\/",
                        "rest": "https:\/\/www.pgsoftgaming.com\/wp-json\/"
                    },
                    "settings": {
                        "lazy_load_background_images": true
                    },
                    "popup": {
                        "hasPopUps": true
                    },
                    "shareButtonsNetworks": {
                        "facebook": {
                            "title": "Facebook",
                            "has_counter": true
                        },
                        "twitter": {
                            "title": "Twitter"
                        },
                        "linkedin": {
                            "title": "LinkedIn",
                            "has_counter": true
                        },
                        "pinterest": {
                            "title": "Pinterest",
                            "has_counter": true
                        },
                        "reddit": {
                            "title": "Reddit",
                            "has_counter": true
                        },
                        "vk": {
                            "title": "VK",
                            "has_counter": true
                        },
                        "odnoklassniki": {
                            "title": "OK",
                            "has_counter": true
                        },
                        "tumblr": {
                            "title": "Tumblr"
                        },
                        "digg": {
                            "title": "Digg"
                        },
                        "skype": {
                            "title": "Skype"
                        },
                        "stumbleupon": {
                            "title": "StumbleUpon",
                            "has_counter": true
                        },
                        "mix": {
                            "title": "Mix"
                        },
                        "telegram": {
                            "title": "Telegram"
                        },
                        "pocket": {
                            "title": "Pocket",
                            "has_counter": true
                        },
                        "xing": {
                            "title": "XING",
                            "has_counter": true
                        },
                        "whatsapp": {
                            "title": "WhatsApp"
                        },
                        "email": {
                            "title": "Email"
                        },
                        "print": {
                            "title": "Print"
                        },
                        "x-twitter": {
                            "title": "X"
                        },
                        "threads": {
                            "title": "Threads"
                        }
                    },
                    "facebook_sdk": {
                        "lang": "zh_CN",
                        "app_id": ""
                    },
                    "lottie": {
                        "defaultAnimationUrl": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/modules\/lottie\/assets\/animations\/default.json"
                    }
                };
            

var elementorFrontendConfig = {
                    "environmentMode": {
                        "edit": false,
                        "wpPreview": false,
                        "isScriptDebug": false
                    },
                    "i18n": {
                        "shareOnFacebook": "\u5728\u8138\u4e66\u4e0a\u5206\u4eab",
                        "shareOnTwitter": "\u5206\u4eab\u5230Twitter",
                        "pinIt": "\u9489\u4f4f",
                        "download": "\u4e0b\u8f7d",
                        "downloadImage": "\u4e0b\u8f7d\u56fe\u7247",
                        "fullscreen": "\u5168\u5c4f",
                        "zoom": "\u7f29\u653e",
                        "share": "\u5206\u4eab",
                        "playVideo": "\u64ad\u653e\u89c6\u9891",
                        "previous": "\u4e0a\u4e00\u9875",
                        "next": "\u4e0b\u4e00\u9875",
                        "close": "\u5173\u95ed",
                        "a11yCarouselPrevSlideMessage": "\u4e0a\u4e00\u5f20\u5e7b\u706f\u7247",
                        "a11yCarouselNextSlideMessage": "\u4e0b\u4e00\u5f20\u5e7b\u706f\u7247",
                        "a11yCarouselFirstSlideMessage": "\u8fd9\u662f\u7b2c\u4e00\u5f20\u5e7b\u706f\u7247",
                        "a11yCarouselLastSlideMessage": "\u8fd9\u662f\u6700\u540e\u4e00\u5f20\u5e7b\u706f\u7247",
                        "a11yCarouselPaginationBulletMessage": "\u8f6c\u5230\u5e7b\u706f\u7247"
                    },
                    "is_rtl": false,
                    "breakpoints": {
                        "xs": 0,
                        "sm": 480,
                        "md": 768,
                        "lg": 1025,
                        "xl": 1440,
                        "xxl": 1600
                    },
                    "responsive": {
                        "breakpoints": {
                            "mobile": {
                                "label": "\u624b\u673a\u7eb5\u5411",
                                "value": 767,
                                "default_value": 767,
                                "direction": "max",
                                "is_enabled": true
                            },
                            "mobile_extra": {
                                "label": "\u624b\u673a\u6a2a\u5411",
                                "value": 880,
                                "default_value": 880,
                                "direction": "max",
                                "is_enabled": false
                            },
                            "tablet": {
                                "label": "\u5e73\u677f\u7535\u8111\u7eb5\u5411",
                                "value": 1024,
                                "default_value": 1024,
                                "direction": "max",
                                "is_enabled": true
                            },
                            "tablet_extra": {
                                "label": "\u5e73\u677f\u7535\u8111\u6a2a\u5411",
                                "value": 1200,
                                "default_value": 1200,
                                "direction": "max",
                                "is_enabled": false
                            },
                            "laptop": {
                                "label": "\u7b14\u8bb0\u672c\u7535\u8111",
                                "value": 1366,
                                "default_value": 1366,
                                "direction": "max",
                                "is_enabled": false
                            },
                            "widescreen": {
                                "label": "\u5168\u5bbd\u5c4f",
                                "value": 2400,
                                "default_value": 2400,
                                "direction": "min",
                                "is_enabled": false
                            }
                        },
                        "hasCustomBreakpoints": false
                    },
                    "version": "3.28.1",
                    "is_static": false,
                    "experimentalFeatures": {
                        "additional_custom_breakpoints": true,
                        "e_local_google_fonts": true,
                        "theme_builder_v2": true,
                        "hello-theme-header-footer": true,
                        "editor_v2": true,
                        "home_screen": true
                    },
                    "urls": {
                        "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor\/assets\/",
                        "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
                        "uploadUrl": "http:\/\/www.pgsoftgaming.com\/wp-content\/uploads"
                    },
                    "nonces": {
                        "floatingButtonsClickTracking": "0520f50238"
                    },
                    "swiperClass": "swiper",
                    "settings": {
                        "page": [],
                        "editorPreferences": []
                    },
                    "kit": {
                        "body_background_background": "classic",
                        "active_breakpoints": ["viewport_mobile", "viewport_tablet"],
                        "global_image_lightbox": "yes",
                        "lightbox_enable_counter": "yes",
                        "lightbox_enable_fullscreen": "yes",
                        "lightbox_enable_zoom": "yes",
                        "lightbox_enable_share": "yes",
                        "lightbox_title_src": "title",
                        "lightbox_description_src": "description",
                        "hello_header_logo_type": "logo",
                        "hello_header_menu_layout": "horizontal",
                        "hello_footer_logo_type": "logo"
                    },
                    "post": {
                        "id": 33231,
                        "title": "%E5%88%98%E4%BA%A6%E8%8F%B2%E7%9A%84%E8%BA%AB%E6%9D%90%E4%BA%89%E8%AE%AE%E5%BC%95%E5%8F%91%E7%83%AD%E8%AE%AE%EF%BC%8C%E6%98%8E%E6%98%9F%E5%81%A5%E5%BA%B7%E6%A0%87%E5%87%86%E5%86%8D%E5%8F%97%E5%85%B3%E6%B3%A8%20%7C%20PG%E7%94%B5%E5%AD%90%E5%AE%98%E6%96%B9%E7%BD%91%E7%AB%99",
                        "excerpt": "",
                        "featuredImage": "https:\/\/www.pgsoftgaming.com\/wp-content\/uploads\/2024090312__\u5218\u4ea6\u83f2\u7684\u8eab\u6750\u4e89\u8bae\u5f15\u53d1\u70ed\u8bae\uff0c\u660e\u661f\u5065\u5eb7\u6807\u51c6\u518d\u53d7\u5173\u6ce8.jpg"
                    }
                };
            

export default {
    data() {
        return {
            //详情信息
            detialData:{},
            navParams: {
                pageNumber: 1,
                pageSize: 10,
                topicId: null
            },
            smallList:[],
            
            newsList:[],
            totalRow:0,
            isloading:false,
            asiderList:[],
            shNewsList:[]
        }
    },
    mounted() {
        const scripts = [
           "./js/webpack-pro.runtime.min.js",
           "./js/webpack.runtime.min.js",
           "./js/frontend.min.js",
           "./js/frontend.mins.js",
           "./js/elements-handlers.min.js",
         
           
           
        ];
        this.loadScripts(scripts, () => {
            console.log('所有脚本加载完成');
            this.loadCSS('./css/post-31562.css',false)

            
        });
        let id = this.$route.query.id;
        if(id) {
            this.getDetail(id);
        }
        this.getAsiderList()
    },
    watch: {
    '$route.query.id'(newId, oldId) {
      // 当 id 发生变化时，重新请求数据
      if (newId !== oldId) {
        this.getDetail(newId);
      }
    }
  },
    components: {
        Footer,
        Header
    },
    methods: {
        toNav(id) {
            if(id) {
                this.$router.push({
                    path: '/techNews',
                    query: {
                        id,
                       
                    }
                });
            }
        },
        //获取侧边栏数据
        async getAsiderList() {
            try {
                let res = await this.req.post('/article/rightSidebar', {});
                this.asiderList = res.data;
                this.shNewsList = res.data[res.data.length-1].articleEntities;
            } catch (error) {
                
            }
        },
        selectTab(id) {
            // 获取当前路由的完整路径，但不包括查询参数
            const currentPath = this.$router.currentRoute.path;

            // 检查是否已经在目标路由上
            if (currentPath === '/newsDetail') {
            // 如果已经在目标路由上，直接更新查询参数
            this.$router.push({
                path: currentPath,
                query: {
                id: id
                }
            });
            } else {
            // 如果不在目标路由上，正常跳转
            this.$router.push({
                path: '/newsDetail',
                query: {
                id: id
                }
            });
            }
        },
        //获取分类的数据
        async getNavList(topicId, id) {
            try {
                this.navParams.topicId = topicId || null;
                let res = await this.req.post('/article/list', this.navParams);
                this.smallList = res.data.list.filter(item => item.id != id);
                this.smallList = this.smallList.splice(0,6)
            } catch (error) {
                
            }
        },
        async getDetail(id) {
            try {
                let res = await this.req.get('/article/getById/'+id);
                this.detialData = res.data;
                this.setMeta(res.data.seoKeys, res.data.seoDesc)
                this.getNavList(res.data.topicId, res.data.id)
            } catch (error) {
                
            }
        },

        setMeta(meta_keys,meta_desc) {
            // const meta_keys = "关键词1, 关键词2, 关键词3";
            // const meta_desc = "这是页面的描述内容";

            // 获取或创建 <meta name="keywords"> 标签
            let keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (!keywordsMeta) {
                keywordsMeta = document.createElement("meta");
                keywordsMeta.setAttribute("name", "keywords");
                document.head.appendChild(keywordsMeta);
            }
            keywordsMeta.setAttribute("content", meta_keys);

            // 获取或创建 <meta name="description"> 标签
            let descriptionMeta = document.querySelector('meta[name="description"]');
            if (!descriptionMeta) {
                descriptionMeta = document.createElement("meta");
                descriptionMeta.setAttribute("name", "description");
                document.head.appendChild(descriptionMeta);
            }
            descriptionMeta.setAttribute("content", meta_desc);
        },
        loadCSS(url, isCache) {
            const element = document.createElement("link");
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            if (isCache) {
            element.setAttribute("href", url + "?t=" + new Date().getTime());
            } else {
            element.setAttribute("href", url);
            }
            document.head.appendChild(element);
        },
        loadScripts(scripts, callback) {
            const loadNext = (index) => {
            if (index >= scripts.length) {
                callback(); // 所有脚本加载完成后执行回调
                return;
            }
            const script = document.createElement('script');
            script.src = scripts[index];
            script.onload = () => loadNext(index + 1); // 加载下一个脚本
            script.onerror = () => console.error(`Failed to load script: ${scripts[index]}`);
            document.body.appendChild(script);
            };
            loadNext(0); // 开始加载第一个脚本
        }
    }
}
</script>


<style scoped lang="less">
@import './css/style.min.css';
@import "./css/frontend.min.css";
@import "./css/widget-icon-list.min.css";
@import "./css/fontawesome.min.css";
@import "./css/widget-divider.min.css";
@import "./css/widget-posts.min.css";
@import "./css/post-4426.css";

#app {
//  background: #230046;

#content {
    margin-top: -80px;
}
}

.elementor-post__thumbnail {
    padding-bottom: calc(0.6* 100%) !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    overflow: hidden !important;
}
.elementor-post__thumbnail__link {
    margin-bottom: -76px !important;
}
.elementor-section {
    margin-bottom: 10px !important;
}
</style>