// src/utils/request.js
import axios from 'axios';
import router from '@/router'; // 引入路由
// import store from '@/store'; // 引入 Vuex store（如果需要）
import { Message } from 'element-ui'; // 引入 Element UI 的消息提示组件

// 创建 Axios 实例
const service = axios.create({
  baseURL: "https://rr66d.com/prod-api", // 基础路径，可以在 .env 文件中配置 http://43.130.46.220/prod-api
  timeout: 5000, // 请求超时时间（毫秒）
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    // const token = store.getters.token; // 假设 token 存储在 Vuex 的 getters 中
    // if (token) {
    //   // 如果有 token，设置到请求头中
    //   config.headers['Authorization'] = `Bearer ${token}`;
    // }

    // 显示加载状态（如果需要）
    // store.dispatch('app/setLoading', true);

    return config;
  },
  (error) => {
    // 请求错误时做些什么
    console.error('请求拦截器错误：', error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const res = response.data;

    // 如果返回的状态码不是 200，则认为是错误
    if (res.code !== 200) {
      // 自定义错误处理逻辑
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000,
      });

      // 如果返回 401，表示 token 过期，跳转到登录页
      if (res.code === 401) {
        store.dispatch('user/resetToken').then(() => {
          Message({
            message: '登录状态已过期，请重新登录',
            type: 'error',
            duration: 5 * 1000,
          });
          setTimeout(() => {
            router.push('/login');
          }, 1000);
        });
      }

      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      // 返回成功的结果
      return res;
    }
  },
  (error) => {
    // 响应错误时做些什么
    console.error('响应拦截器错误：', error);

    // 获取错误状态码
    const status = error.response && error.response.status;

    // 根据不同的状态码进行处理
    if (status) {
      switch (status) {
        case 400:
          Message.error('请求错误');
          break;
        case 401:
          Message.error('未授权，请重新登录');
          // 清除 token 并跳转到登录页
          store.dispatch('user/resetToken').then(() => {
            router.push('/login');
          });
          break;
        case 403:
          Message.error('拒绝访问');
          break;
        case 404:
          Message.error('请求资源不存在');
          break;
        case 500:
          Message.error('服务器内部错误');
          break;
        case 502:
          Message.error('网关错误');
          break;
        case 504:
          Message.error('网关超时');
          break;
        default:
          Message.error(error.message || '未知错误');
      }
    } else {
      // 网络错误
      Message.error('网络连接失败，请检查网络');
    }

    return Promise.reject(error);
  }
);

// 导出常用的 HTTP 方法
export default {
  get: (url, params) => {
    return service.get(url, { params });
  },
  post: (url, data) => {
    return service.post(url, data);
  },
  put: (url, data) => {
    return service.put(url, data);
  },
  delete: (url, params) => {
    return service.delete(url, { params });
  },
  request: (config) => {
    return service(config);
  },
};