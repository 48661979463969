<!--
 * @Author: 亦云 yiyundev@163.com
 * @Date: 2025-02-13 13:08:35
 * @LastEditTime: 2025-03-03 19:04:04
 * @FilePath: \sw_project\src\index\index.vue
 * @Description: 首页
-->

<template>
    <div id="app"  class="home page-template-default page page-id-33263 wp-custom-logo unselectable elementor-default elementor-kit-5 elementor-page elementor-page-33263">
        <!-- 头部及弹窗 -->
        <Header></Header>
        <main @click="closeModelHandle" id="content" class="site-main post-33263 page type-page status-publish hentry">
            <div class="page-content">
                <div data-elementor-type="wp-page" data-elementor-id="33263" class="elementor elementor-33263" data-elementor-post-type="page">
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-236d550a elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="236d550a" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;background_motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;background_motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;background_motion_fx_scale_direction&quot;:&quot;in-out&quot;,&quot;background_motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;2.5&quot;,&quot;sizes&quot;:[]},&quot;background_motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;80&quot;}},&quot;background_motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}">
                        <div class="elementor-background-overlay"></div>
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-f04ef91" data-id="f04ef91" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-2845cbd elementor-widget__width-initial elementor-invisible elementor-widget elementor-widget-image" data-id="2845cbd" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;}" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <img fetchpriority="high" decoding="async" width="404" height="238" src="../assets/indeximages/PGLOGO2024.png" class="attachment-large size-large wp-image-26901" alt=""/>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-ab1e048 elementor-invisible elementor-widget elementor-widget-image" data-id="ab1e048" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInUp&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <img decoding="async" width="500" height="133" src="../assets/indeximages/PG最佳電子遊戲.webp" class="attachment-large size-large wp-image-34815" alt=""/>
                                        </div>
                                    </div>
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-3920974 elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="3920974" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:400}">
                                        <div class="elementor-container elementor-column-gap-narrow">
                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5d63000" data-id="5d63000" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-a5b0838 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-initial elementor-widget elementor-widget-button" data-id="a5b0838" data-element_type="widget" data-widget_type="button.default">
                                                        <div id="swdtBox" class="elementor-widget-container">
                                                            <div class="elementor-button-wrapper">
                                                                <a @click.prevent="$router.push('/xboxOne')" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                                    <span class="elementor-button-content-wrapper">
                                                                        <span class="elementor-button-icon">
                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                        </span>
                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-8592375 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-initial elementor-widget elementor-widget-button" data-id="8592375" data-element_type="widget" data-widget_type="button.default">
                                                        <div id="rejestBox" class="elementor-widget-container">
                                                            <div class="elementor-button-wrapper">
                                                                <a class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                    <span class="elementor-button-content-wrapper">
                                                                        <span class="elementor-button-text">新注册最高可领8,384</span>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-38f86999 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="38f86999" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-761999c4" data-id="761999c4" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-6923004c elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6923004c" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-362ea327" data-id="362ea327" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-42436801 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="42436801" data-element_type="widget" data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h4 class="elementor-heading-title elementor-size-default">Games Demo</h4>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-70015e7f elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="70015e7f" data-element_type="widget" data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">热门游戏试玩</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-22200665" data-id="22200665" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-36502c75 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="36502c75" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;motion_fx_scale_direction&quot;:&quot;in-out&quot;,&quot;motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;-10&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="divider.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-divider">
                                                                <span class="elementor-divider-separator"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-5a2f290 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5a2f290" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-wide">
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-b25e1ef elementor-invisible" data-id="b25e1ef" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-9196fef elementor-widget elementor-widget-spacer" data-id="9196fef" data-element_type="widget" data-widget_type="spacer.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-spacer">
                                                                <div class="elementor-spacer-inner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-e65ee58 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="e65ee58" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7addbc1" data-id="7addbc1" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-039e1b3 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="039e1b3" data-element_type="widget" data-widget_type="image.default">
                                                                        <div class="elementor-widget-container">
                                                                            <img loading="lazy" decoding="async" width="186" height="186" src="../assets/indeximages/AnyConv.com__app_icon_small_3x.webp" class="attachment-full size-full wp-image-101" alt="" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-88f629e" data-id="88f629e" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-9137de3 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="9137de3" data-element_type="widget" data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <p>
                                                                                <span style="vertical-align: inherit;">
                                                                                    <span style="vertical-align: inherit;">
                                                                                        <strong>麻将胡了</strong>
                                                                                        <br/>
                                                                                        麻将三缺一，等您来开杠！<br/>
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-e4659de elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="e4659de" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4349d5f" data-id="4349d5f" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-df1e02d elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="df1e02d" data-element_type="widget" data-widget_type="button.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a @click.prevent="$router.push('/xboxOne')" id="headerBtnBox" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-63fd140 elementor-invisible" data-id="63fd140" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-186b3fd elementor-widget elementor-widget-spacer" data-id="186b3fd" data-element_type="widget" data-widget_type="spacer.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-spacer">
                                                                <div class="elementor-spacer-inner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-c8fdb96 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="c8fdb96" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1014578" data-id="1014578" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-176c7b9 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="176c7b9" data-element_type="widget" data-widget_type="image.default">
                                                                        <div class="elementor-widget-container">
                                                                            <img loading="lazy" decoding="async" width="186" height="186" src="../assets/indeximages/AnyConv.com__麻将方式-2.webp" class="attachment-full size-full wp-image-112" alt="" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-b5b4896" data-id="b5b4896" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-79bd43b elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="79bd43b" data-element_type="widget" data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <p>
                                                                                <span style="vertical-align: inherit;">
                                                                                    <span style="vertical-align: inherit;">
                                                                                        <strong>麻将胡了2</strong>
                                                                                        <br/>
                                                                                        人气《麻将胡了2》火热来袭！<br/>
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-c978b00 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="c978b00" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-b548fa0" data-id="b548fa0" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-c686eb7 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="c686eb7" data-element_type="widget" data-widget_type="button.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a @click.prevent="$router.push('/xboxOne')" id="headerBtnBox" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-ece35b1 elementor-invisible" data-id="ece35b1" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-95a2633 elementor-widget elementor-widget-spacer" data-id="95a2633" data-element_type="widget" data-widget_type="spacer.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-spacer">
                                                                <div class="elementor-spacer-inner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-66f2ba3 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="66f2ba3" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-12c8b9c" data-id="12c8b9c" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-529eb04 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="529eb04" data-element_type="widget" data-widget_type="image.default">
                                                                        <div class="elementor-widget-container">
                                                                            <img loading="lazy" decoding="async" width="186" height="186" src="../assets/indeximages/AnyConv.com__app_icon_small_3x-39.webp" class="attachment-full size-full wp-image-73" alt="AnyConv.com_赏金女王"  sizes="(max-width: 186px) 100vw, 186px"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-cac2031" data-id="cac2031" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-18bc8d0 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="18bc8d0" data-element_type="widget" data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <p>
                                                                                <span style="vertical-align: inherit;">
                                                                                    <strong>赏金女王</strong>
                                                                                    <br/>夺走藏宝图，寻找尘封的宝藏!
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-52fe250 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="52fe250" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-132d74b" data-id="132d74b" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-a5b1f3c elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="a5b1f3c" data-element_type="widget" data-widget_type="button.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a @click.prevent="$router.push('/xboxOne')" id="headerBtnBox" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0b92b74 elementor-invisible" data-id="0b92b74" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-4094bde elementor-widget elementor-widget-spacer" data-id="4094bde" data-element_type="widget" data-widget_type="spacer.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-spacer">
                                                                <div class="elementor-spacer-inner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-97e183b elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="97e183b" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-17d5e18" data-id="17d5e18" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-03d3cf0 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="03d3cf0" data-element_type="widget" data-widget_type="image.default">
                                                                        <div class="elementor-widget-container">
                                                                            <img loading="lazy" decoding="async" width="186" height="186" src="../assets/indeximages/AnyConv.com__app_icon_small_3x-14.webp" class="attachment-full size-full wp-image-48" alt="AnyConv.com_发财鱼虾蟹" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-bec6655" data-id="bec6655" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-0b7e696 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="0b7e696" data-element_type="widget" data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <span style="vertical-align: inherit;">
                                                                                <span style="vertical-align: inherit;">
                                                                                    <strong>发财鱼虾蟹</strong>
                                                                                    鱼虾蟹经典再现，连赢连送福气旺！

                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-81533e6 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="81533e6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-840acc1" data-id="840acc1" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-e42ba1c elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="e42ba1c" data-element_type="widget" data-widget_type="button.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a @click.prevent="$router.push('/xboxOne')" id="headerBtnBox" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="#">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-c7a538e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c7a538e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-896c221" data-id="896c221" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-baab53e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="baab53e" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ee07b3b" data-id="ee07b3b" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-4c77c63 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="4c77c63" data-element_type="widget" data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h4 class="elementor-heading-title elementor-size-default">Featured Games​</h4>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-755fa3a elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="755fa3a" data-element_type="widget" data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">PG特色游戏</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-d8fa43c" data-id="d8fa43c" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-1be5e16 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="1be5e16" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;motion_fx_scale_direction&quot;:&quot;in-out&quot;,&quot;motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;-10&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="divider.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-divider">
                                                                <span class="elementor-divider-separator"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-13335f73 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="13335f73" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4a56cba2" data-id="4a56cba2" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-c05c1be elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="c05c1be" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">麻将胡了系列</h2>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-3534804a elementor-widget elementor-widget-text-editor" data-id="3534804a" data-element_type="widget" data-widget_type="text-editor.default">
                                        <div class="elementor-widget-container">
                                            <p>麻將胡了、麻將胡了2 是一款 5 捲軸、4 行（第 2、3 和 4 捲軸各附加一行）視頻老虎機，具有百搭符號變換和乘數遞增的自由旋轉。</p>
                                            <p>在免費旋轉功能期間，最大獎金乘數將增加至 10 倍，而除捲軸 3 中的 Wild 符號和 Scatter 符號之外的所有符號都將變成金色麻將符號！</p>
                                            <p>獲勝旋轉中的金色麻將符號也會轉換為百搭符號，讓你贏不停！</p>
                                            <p>支持平台有iOS, Android, HTML5, MacOS, Windows，最高赢奖倍数為x100000，RTP為96.95% (RTP是Return to Player，稱作玩家返還率，代表玩家投注與其回報的比例，一般是用百分比呈現，RTP值越高代表對玩家越有利。)</p>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-2fbe0a6 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-initial elementor-widget elementor-widget-button" data-id="2fbe0a6" data-element_type="widget" data-widget_type="button.default">
                                        <div class="elementor-widget-container">
                                            <div class="elementor-button-wrapper">
                                                <a class="elementor-button elementor-button-link elementor-size-sm" href="https://rr09.vip" target="_blank">
                                                    <span class="elementor-button-content-wrapper">
                                                        <span class="elementor-button-icon">
                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                        </span>
                                                        <span class="elementor-button-text">点击试玩</span>
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-121deb35" data-id="121deb35" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-75932e82 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-image" data-id="75932e82" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_direction&quot;:&quot;negative&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;1.5&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <img loading="lazy" decoding="async" width="1018" height="848" src="../assets/indeximages/PG-15.png" class="attachment-large size-large wp-image-33468" alt=""  sizes="(max-width: 1018px) 100vw, 1018px"/>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-280c542c elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-image" data-id="280c542c" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;2&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="image.default">
                                        <div class="elementor-widget-container">
                                            <img loading="lazy" decoding="async" width="576" height="576" src="../assets/indeximages/AnyConv.com__50c6fa4a-4661-4ac6-8c41-938c8eea873f.webp" class="attachment-large size-large wp-image-3068" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-6bb499c6 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="6bb499c6" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6c6a55bc" data-id="6c6a55bc" data-element_type="column">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-51c6f6d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="51c6f6d" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-3dd1d72" data-id="3dd1d72" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-264f0e9 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="264f0e9" data-element_type="widget" data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h4 class="elementor-heading-title elementor-size-default">High RTP Games</h4>
                                                        </div>
                                                    </div>
                                                    <div class="elementor-element elementor-element-1ec0240 elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="1ec0240" data-element_type="widget" data-widget_type="heading.default">
                                                        <div class="elementor-widget-container">
                                                            <h2 class="elementor-heading-title elementor-size-default">高爆率游戏</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5967acc" data-id="5967acc" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-a30377c elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="a30377c" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_scale_effect&quot;:&quot;yes&quot;,&quot;motion_fx_scale_direction&quot;:&quot;in-out&quot;,&quot;motion_fx_scale_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;-10&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_scale_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="divider.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-divider">
                                                                <span class="elementor-divider-separator"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-4b021f3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4b021f3" data-element_type="section">
                                        <div class="elementor-container elementor-column-gap-wide">
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-0227c0f elementor-invisible" data-id="0227c0f" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-83d6499 elementor-widget elementor-widget-spacer" data-id="83d6499" data-element_type="widget" data-widget_type="spacer.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-spacer">
                                                                <div class="elementor-spacer-inner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-0a5f8d1 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="0a5f8d1" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d437d6b" data-id="d437d6b" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-6b1b776 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="6b1b776" data-element_type="widget" data-widget_type="image.default">
                                                                        <div class="elementor-widget-container">
                                                                            <img loading="lazy" decoding="async" width="186" height="186" src="../assets/indeximages/AnyConv.com__app_icon_small_3x-6.webp" class="attachment-full size-full wp-image-40" alt="" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-df4892c" data-id="df4892c" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-6b27efb elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="6b27efb" data-element_type="widget" data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <p>
                                                                                <span style="vertical-align: inherit;">
                                                                                    <span style="vertical-align: inherit;">
                                                                                        <strong>忍者vs武侍</strong>
                                                                                        <br/>
                                                                                        高达15,000倍投注翻倍<br/>
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-c2e9f2e elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="c2e9f2e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-100b937" data-id="100b937" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-44c8cf2 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="44c8cf2" data-element_type="widget" data-widget_type="button.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a @click.prevent="$router.push('/xboxOne')" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-c35cf92 elementor-invisible" data-id="c35cf92" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:300}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-ba90dc7 elementor-widget elementor-widget-spacer" data-id="ba90dc7" data-element_type="widget" data-widget_type="spacer.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-spacer">
                                                                <div class="elementor-spacer-inner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-828643f elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="828643f" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-cf3791e" data-id="cf3791e" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-479e321 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="479e321" data-element_type="widget" data-widget_type="image.default">
                                                                        <div class="elementor-widget-container">
                                                                            <img loading="lazy" decoding="async" width="186" height="186" src="../assets/indeximages/AnyConv.com__app_icon_small_3x-7.webp" class="attachment-full size-full wp-image-41" alt="拳霸" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-c0171a9" data-id="c0171a9" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-40f3ef8 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="40f3ef8" data-element_type="widget" data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <p>
                                                                                <span style="vertical-align: inherit;">
                                                                                    <span style="vertical-align: inherit;">
                                                                                        <strong>拳霸</strong>
                                                                                        <br/>
                                                                                        借助泰拳的力量擊敗對手<br/>
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-93a3ee2 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="93a3ee2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6582436" data-id="6582436" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-bcb0be9 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="bcb0be9" data-element_type="widget" data-widget_type="button.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a @click.prevent="$router.push('/xboxOne')" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-841016b elementor-invisible" data-id="841016b" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:600}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-2ab4ce2 elementor-widget elementor-widget-spacer" data-id="2ab4ce2" data-element_type="widget" data-widget_type="spacer.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-spacer">
                                                                <div class="elementor-spacer-inner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-b81bda2 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="b81bda2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-5f31d59" data-id="5f31d59" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-b2e696a elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="b2e696a" data-element_type="widget" data-widget_type="image.default">
                                                                        <div class="elementor-widget-container">
                                                                            <img loading="lazy" decoding="async" width="156" height="156" src="../assets/indeximages/黄金摇钱树-1.webp" class="attachment-full size-full wp-image-1779" alt="黄金摇钱树"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-b62bb8b" data-id="b62bb8b" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-a426a94 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="a426a94" data-element_type="widget" data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <p>
                                                                                <span style="vertical-align: inherit;">
                                                                                    <strong>黄金摇钱树</strong>
                                                                                    <br/>金树摇一摇，黄金装满袋！
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-f4d5b3c elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="f4d5b3c" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-105f588" data-id="105f588" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-f2d8302 elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="f2d8302" data-element_type="widget" data-widget_type="button.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a @click.prevent="$router.push('/xboxOne')" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-e580280 elementor-invisible" data-id="e580280" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:900}">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-caeae3b elementor-widget elementor-widget-spacer" data-id="caeae3b" data-element_type="widget" data-widget_type="spacer.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-spacer">
                                                                <div class="elementor-spacer-inner"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-b2504d3 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="b2504d3" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-48f8866" data-id="48f8866" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-cc2fa46 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="cc2fa46" data-element_type="widget" data-widget_type="image.default">
                                                                        <div class="elementor-widget-container">
                                                                            <img loading="lazy" decoding="async" width="186" height="186" src="../assets/indeximages/AnyConv.com__app_icon_small_3x-32.webp" class="attachment-full size-full wp-image-66" alt="美猴王传奇" sizes="(max-width: 186px) 100vw, 186px"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="elementor-column elementor-col-66 elementor-inner-column elementor-element elementor-element-3e4f477" data-id="3e4f477" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-c21b0e6 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-widget elementor-widget-text-editor" data-id="c21b0e6" data-element_type="widget" data-widget_type="text-editor.default">
                                                                        <div class="elementor-widget-container">
                                                                            <p>
                                                                                <span style="vertical-align: inherit;">
                                                                                    <span style="vertical-align: inherit;">
                                                                                        <strong>美猴王传奇</strong>
                                                                                        <br/>
                                                                                        天宫里捣乱、征服天下！<br/>
                                                                                    </span>
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-c539267 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="c539267" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                        <div class="elementor-container elementor-column-gap-narrow">
                                                            <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6aac66d" data-id="6aac66d" data-element_type="column">
                                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                                    <div class="elementor-element elementor-element-486159c elementor-align-justify elementor-mobile-align-justify elementor-widget__width-inherit elementor-widget elementor-widget-button" data-id="486159c" data-element_type="widget" data-widget_type="button.default">
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a @click.prevent="$router.push('/xboxOne')" class="elementor-button elementor-button-link elementor-size-sm" href="#">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-icon">
                                                                                            <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text">PG试玩大厅</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-19b02286 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="19b02286" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-101d9ec8" data-id="101d9ec8" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-39e6c411 elementor-widget__width-inherit elementor-widget elementor-widget-heading" data-id="39e6c411" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h4 class="elementor-heading-title elementor-size-default">OUR FEATURES</h4>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-406c140e elementor-widget elementor-widget-heading" data-id="406c140e" data-element_type="widget" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">PG电子游戏特色</h2>
                                        </div>
                                    </div>
                                    <section class="elementor-section elementor-inner-section elementor-element elementor-element-225c70b8 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="225c70b8" data-element_type="section" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_opacity_effect&quot;:&quot;yes&quot;,&quot;motion_fx_opacity_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;],&quot;motion_fx_opacity_direction&quot;:&quot;out-in&quot;,&quot;motion_fx_opacity_level&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]}}">
                                        <div class="elementor-container elementor-column-gap-default">
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-1a19a210" data-id="1a19a210" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-44ee4f60 elementor-position-top elementor-widget elementor-widget-image-box" data-id="44ee4f60" data-element_type="widget" data-widget_type="image-box.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-image-box-wrapper">
                                                                <figure class="elementor-image-box-img">
                                                                    <img loading="lazy" decoding="async" width="280" height="280" src="../assets/indeximages/screen5_social@2x.28914a4.png" class="attachment-full size-full wp-image-33448" alt=""/>
                                                                </figure>
                                                                <div class="elementor-image-box-content">
                                                                    <h3 class="elementor-image-box-title">Social</h3>
                                                                    <p class="elementor-image-box-description">PG SOFT™是第一家推出「社交系统」的公司。玩家能够透过群组聊天或发送表情符号与本地或全球玩家互动。</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-7ab8023" data-id="7ab8023" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-0b13dd6 elementor-position-top elementor-widget elementor-widget-image-box" data-id="0b13dd6" data-element_type="widget" data-widget_type="image-box.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-image-box-wrapper">
                                                                <figure class="elementor-image-box-img">
                                                                    <img loading="lazy" decoding="async" width="280" height="280" src="../assets/indeximages/screen5_freegame@2x.b17e435.png" class="attachment-full size-full wp-image-33446" alt=""/>
                                                                </figure>
                                                                <div class="elementor-image-box-content">
                                                                    <h3 class="elementor-image-box-title">Free Game</h3>
                                                                    <p class="elementor-image-box-description">PG SOFT™免费游戏和奖励可让营运商根据不同的用户等级自由自订功能。以及大量增加或添加不同的用户。</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-88785c9" data-id="88785c9" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-7c1accb elementor-position-top elementor-widget elementor-widget-image-box" data-id="7c1accb" data-element_type="widget" data-widget_type="image-box.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-image-box-wrapper">
                                                                <figure class="elementor-image-box-img">
                                                                    <img loading="lazy" decoding="async" width="280" height="280" src="../assets/indeximages/screen5_tournament@2x.50637b0.png" class="attachment-full size-full wp-image-33445" alt=""/>
                                                                </figure>
                                                                <div class="elementor-image-box-content">
                                                                    <h3 class="elementor-image-box-title">Tournament</h3>
                                                                    <p class="elementor-image-box-description">家聚集在竞争激烈的游戏竞技场上，争夺诱人的奖励和奖品。促进促销活动并增加用户群和会员忠诚度。</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-6015e79" data-id="6015e79" data-element_type="column">
                                                <div class="elementor-widget-wrap elementor-element-populated">
                                                    <div class="elementor-element elementor-element-faa0529 elementor-position-top elementor-widget elementor-widget-image-box" data-id="faa0529" data-element_type="widget" data-widget_type="image-box.default">
                                                        <div class="elementor-widget-container">
                                                            <div class="elementor-image-box-wrapper">
                                                                <figure class="elementor-image-box-img">
                                                                    <img loading="lazy" decoding="async" width="280" height="280" src="../assets/indeximages/screen5_smartbot@2x.9af8fed.png" class="attachment-full size-full wp-image-33447" alt=""/>
                                                                </figure>
                                                                <div class="elementor-image-box-content">
                                                                    <h3 class="elementor-image-box-title">Smartbot</h3>
                                                                    <p class="elementor-image-box-description">Smartbot 是一项专有技术，旨在允许用户在应用程式之间切换，同时保持游戏在背景运行。</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="elementor-section elementor-top-section elementor-element elementor-element-2d8bfd95 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle" data-id="2d8bfd95" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                        <div class="elementor-background-overlay"></div>
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-20c708a6" data-id="20c708a6" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                <div class="elementor-widget-wrap elementor-element-populated">
                                    <div class="elementor-element elementor-element-71d43d87 elementor-widget elementor-widget-heading" data-id="71d43d87" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;0.5&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_translateY_direction&quot;:&quot;negative&quot;,&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h2 class="elementor-heading-title elementor-size-default">Pocket Games Soft</h2>
                                        </div>
                                    </div>
                                    <div class="elementor-element elementor-element-19fd149c elementor-widget elementor-widget-heading" data-id="19fd149c" data-element_type="widget" data-settings="{&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_opacity_effect&quot;:&quot;yes&quot;,&quot;motion_fx_opacity_range&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:&quot;0&quot;,&quot;end&quot;:&quot;50&quot;}},&quot;motion_fx_opacity_direction&quot;:&quot;out-in&quot;,&quot;motion_fx_opacity_level&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:10,&quot;sizes&quot;:[]},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="heading.default">
                                        <div class="elementor-widget-container">
                                            <h4 class="elementor-heading-title elementor-size-default">DIFFERENCE MAKES
            </br>THE DIFFERENCE</h4></div></div></div></div></div></section>
            <section class="elementor-section elementor-top-section elementor-element elementor-element-13be2f70 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="13be2f70" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                <div class="elementor-container elementor-column-gap-default">
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d78aafe" data-id="d78aafe" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-26c4e88 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-image" data-id="26c4e88" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_direction&quot;:&quot;negative&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;1&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <img loading="lazy" decoding="async" width="696" height="666" src="../assets/indeximages/a73639a79025fc1a7d5c7a35e45fd31b.png" class="attachment-large size-large wp-image-2315" alt="PG电子游戏试玩平台"/>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-662e69d7 elementor-widget__width-initial elementor-absolute elementor-widget-tablet__width-initial elementor-widget-mobile__width-initial elementor-widget elementor-widget-image" data-id="662e69d7" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;,&quot;motion_fx_motion_fx_scrolling&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_effect&quot;:&quot;yes&quot;,&quot;motion_fx_translateY_speed&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:&quot;1&quot;,&quot;sizes&quot;:[]},&quot;motion_fx_translateY_affectedRange&quot;:{&quot;unit&quot;:&quot;%&quot;,&quot;size&quot;:&quot;&quot;,&quot;sizes&quot;:{&quot;start&quot;:0,&quot;end&quot;:100}},&quot;motion_fx_devices&quot;:[&quot;desktop&quot;,&quot;tablet&quot;,&quot;mobile&quot;]}" data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <!-- srcset="https://www.pgsoftgaming.com/wp-content/uploads/PG-14.png 2201w, https://www.pgsoftgaming.com/wp-content/uploads/PG-14-768x398.png 768w, https://www.pgsoftgaming.com/wp-content/uploads/PG-14-1536x796.png 1536w, https://www.pgsoftgaming.com/wp-content/uploads/PG-14-2048x1061.png 2048w" -->
                                    <img loading="lazy" decoding="async" width="2201" height="1140" src="../assets/indeximages/PG-14.png" class="attachment-large size-large wp-image-33469" alt=""  sizes="(max-width: 2201px) 100vw, 2201px"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-23d19522" data-id="23d19522" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-7e1b4155 elementor-widget elementor-widget-heading" data-id="7e1b4155" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h4 class="elementor-heading-title elementor-size-default">2024 PG电子试玩大厅</h4>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-27bc6749 elementor-widget elementor-widget-heading" data-id="27bc6749" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">新手专属签到礼包</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-aee916f elementor-widget__width-auto elementor-widget elementor-widget-heading" data-id="aee916f" data-element_type="widget" data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <h2 class="elementor-heading-title elementor-size-default">最高 8,384！</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-5455b6a elementor-widget elementor-widget-shortcode" data-id="5455b6a" data-element_type="widget" data-widget_type="shortcode.default">
                                <div class="elementor-widget-container">
                                    <div class="elementor-shortcode">
                                        <div data-elementor-type="section" data-elementor-id="33905" class="elementor elementor-33905" data-elementor-post-type="elementor_library">
                                            <section class="elementor-section elementor-top-section elementor-element elementor-element-2547e90 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="2547e90" data-element_type="section">
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b9c4a00" data-id="b9c4a00" data-element_type="column">
                                                        <div class="elementor-widget-wrap elementor-element-populated">
                                                            <div class="elementor-element elementor-element-ea97123 elementor-widget elementor-widget-button" data-id="ea97123" data-element_type="widget" data-widget_type="button.default">
                                                                <div  class="elementor-widget-container">
                                                                    <div class="elementor-button-wrapper">
                                                                        <a id="headerBtnBox" class="elementor-button elementor-button-link elementor-size-sm elementor-animation-grow" href="https://rr.top" target="_blank" rel="nofollow">
                                                                            <span class="elementor-button-content-wrapper">
                                                                                <span class="elementor-button-icon">
                                                                                    <i aria-hidden="true" class="fas fa-angle-double-right"></i>
                                                                                </span>
                                                                                <span class="elementor-button-text">2025 抢百万蛇我其谁</span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
            </div>
        </main>
        <!-- 底部 -->
        <Footer></Footer>
   </div>
</template>

<script>
import Footer from "../components/footer.vue";
import Header from "../components/header.vue";
var ElementorProFrontendConfig = {
                "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
                "nonce": "bba1253488",
                "urls": {
                    "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/assets\/",
                    "rest": "https:\/\/www.pgsoftgaming.com\/wp-json\/"
                },
                "settings": {
                    "lazy_load_background_images": true
                },
                "popup": {
                    "hasPopUps": true
                },
                "shareButtonsNetworks": {
                    "facebook": {
                        "title": "Facebook",
                        "has_counter": true
                    },
                    "twitter": {
                        "title": "Twitter"
                    },
                    "linkedin": {
                        "title": "LinkedIn",
                        "has_counter": true
                    },
                    "pinterest": {
                        "title": "Pinterest",
                        "has_counter": true
                    },
                    "reddit": {
                        "title": "Reddit",
                        "has_counter": true
                    },
                    "vk": {
                        "title": "VK",
                        "has_counter": true
                    },
                    "odnoklassniki": {
                        "title": "OK",
                        "has_counter": true
                    },
                    "tumblr": {
                        "title": "Tumblr"
                    },
                    "digg": {
                        "title": "Digg"
                    },
                    "skype": {
                        "title": "Skype"
                    },
                    "stumbleupon": {
                        "title": "StumbleUpon",
                        "has_counter": true
                    },
                    "mix": {
                        "title": "Mix"
                    },
                    "telegram": {
                        "title": "Telegram"
                    },
                    "pocket": {
                        "title": "Pocket",
                        "has_counter": true
                    },
                    "xing": {
                        "title": "XING",
                        "has_counter": true
                    },
                    "whatsapp": {
                        "title": "WhatsApp"
                    },
                    "email": {
                        "title": "Email"
                    },
                    "print": {
                        "title": "Print"
                    },
                    "x-twitter": {
                        "title": "X"
                    },
                    "threads": {
                        "title": "Threads"
                    }
                },
                "facebook_sdk": {
                    "lang": "zh_CN",
                    "app_id": ""
                },
                "lottie": {
                    "defaultAnimationUrl": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/modules\/lottie\/assets\/animations\/default.json"
                }
};
var elementorFrontendConfig = {
    "environmentMode": {
        "edit": false,
        "wpPreview": false,
        "isScriptDebug": false
    },
    "i18n": {
        "shareOnFacebook": "\u5728\u8138\u4e66\u4e0a\u5206\u4eab",
        "shareOnTwitter": "\u5206\u4eab\u5230Twitter",
        "pinIt": "\u9489\u4f4f",
        "download": "\u4e0b\u8f7d",
        "downloadImage": "\u4e0b\u8f7d\u56fe\u7247",
        "fullscreen": "\u5168\u5c4f",
        "zoom": "\u7f29\u653e",
        "share": "\u5206\u4eab",
        "playVideo": "\u64ad\u653e\u89c6\u9891",
        "previous": "\u4e0a\u4e00\u9875",
        "next": "\u4e0b\u4e00\u9875",
        "close": "\u5173\u95ed",
        "a11yCarouselPrevSlideMessage": "\u4e0a\u4e00\u5f20\u5e7b\u706f\u7247",
        "a11yCarouselNextSlideMessage": "\u4e0b\u4e00\u5f20\u5e7b\u706f\u7247",
        "a11yCarouselFirstSlideMessage": "\u8fd9\u662f\u7b2c\u4e00\u5f20\u5e7b\u706f\u7247",
        "a11yCarouselLastSlideMessage": "\u8fd9\u662f\u6700\u540e\u4e00\u5f20\u5e7b\u706f\u7247",
        "a11yCarouselPaginationBulletMessage": "\u8f6c\u5230\u5e7b\u706f\u7247"
    },
    "is_rtl": false,
    "breakpoints": {
        "xs": 0,
        "sm": 480,
        "md": 768,
        "lg": 1025,
        "xl": 1440,
        "xxl": 1600
    },
    "responsive": {
        "breakpoints": {
            "mobile": {
                "label": "\u624b\u673a\u7eb5\u5411",
                "value": 767,
                "default_value": 767,
                "direction": "max",
                "is_enabled": true
            },
            "mobile_extra": {
                "label": "\u624b\u673a\u6a2a\u5411",
                "value": 880,
                "default_value": 880,
                "direction": "max",
                "is_enabled": false
            },
            "tablet": {
                "label": "\u5e73\u677f\u7535\u8111\u7eb5\u5411",
                "value": 1024,
                "default_value": 1024,
                "direction": "max",
                "is_enabled": true
            },
            "tablet_extra": {
                "label": "\u5e73\u677f\u7535\u8111\u6a2a\u5411",
                "value": 1200,
                "default_value": 1200,
                "direction": "max",
                "is_enabled": false
            },
            "laptop": {
                "label": "\u7b14\u8bb0\u672c\u7535\u8111",
                "value": 1366,
                "default_value": 1366,
                "direction": "max",
                "is_enabled": false
            },
            "widescreen": {
                "label": "\u5168\u5bbd\u5c4f",
                "value": 2400,
                "default_value": 2400,
                "direction": "min",
                "is_enabled": false
            }
        },
        "hasCustomBreakpoints": false
    },
    "version": "3.27.3",
    "is_static": false,
    "experimentalFeatures": {
        "additional_custom_breakpoints": true,
        "e_swiper_latest": true,
        "e_onboarding": true,
        "theme_builder_v2": true,
        "hello-theme-header-footer": true,
        "home_screen": true,
        "landing-pages": true,
        "editor_v2": true,
        "link-in-bio": true,
        "floating-buttons": true
    },
    "urls": {
        "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor\/assets\/",
        "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
        "uploadUrl": "http:\/\/www.pgsoftgaming.com\/wp-content\/uploads"
    },
    "nonces": {
        "floatingButtonsClickTracking": "f120a008d0"
    },
    "swiperClass": "swiper",
    "settings": {
        "page": [],
        "editorPreferences": []
    },
    "kit": {
        "body_background_background": "classic",
        "active_breakpoints": ["viewport_mobile", "viewport_tablet"],
        "global_image_lightbox": "yes",
        "lightbox_enable_counter": "yes",
        "lightbox_enable_fullscreen": "yes",
        "lightbox_enable_zoom": "yes",
        "lightbox_enable_share": "yes",
        "lightbox_title_src": "title",
        "lightbox_description_src": "description",
        "hello_header_logo_type": "logo",
        "hello_header_menu_layout": "horizontal",
        "hello_footer_logo_type": "logo"
    },
    "post": {
        "id": 33263,
        "title": "PG%E7%94%B5%E5%AD%90%E5%AE%98%E6%96%B9%E7%BD%91%E7%AB%99%20%7C%20PG%E7%94%B5%E5%AD%90%E5%AE%98%E6%96%B9%E7%BD%91%E7%AB%99",
        "excerpt": "",
        "featuredImage": false
    }
};
export default {
    name: 'IndexBox',
    props:['ismodel'],
    components: {
        Footer,
        Header
    },
    data() {
        return {
            isShow:false,
        }
    },
    onload() {
        // const scripts = [
        //     'https://www.pgsoftgaming.com/wp-includes/js/jquery/jquery.min.js?ver=3.7.1',
        //     "https://www.pgsoftgaming.com/wp-includes/js/jquery/jquery-migrate.min.js?ver=3.4.1",
        // ];
        // this.loadScripts(scripts, () => {
        //     console.log('所有脚本加载完成');
        // });
    },
    mounted() {
        
        const scripts = [
            // 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/webpack-pro.runtime.min.js?ver=3.27.3',
            "./js/webpack-pro.runtime.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/webpack.runtime.min.js?ver=3.27.3",
            "./js/webpack.runtime.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend-modules.min.js?ver=3.27.3",
            "./js/frontend-modules.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/frontend.min.js?ver=3.27.3",
            "./js/frontend.min.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/js/frontend.min.js?ver=3.27.3",
            "./js/frontend.min1.js",
            // "https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/js/elements-handlers.min.js?ver=3.27.3"
            "elements-handlers.min.js"
        ];
        this.loadScripts(scripts, () => {
            console.log('所有脚本加载完成');
        });
    },
    methods: {
        closeModelHandle() {
            this.$bus.$emit('closeModelHandle')
        },
      
        loadScripts(scripts, callback) {
            const loadNext = (index) => {
            if (index >= scripts.length) {
                callback(); // 所有脚本加载完成后执行回调
                return;
            }
            const script = document.createElement('script');
            script.src = scripts[index];
            script.onload = () => loadNext(index + 1); // 加载下一个脚本
            script.onerror = () => console.error(`Failed to load script: ${scripts[index]}`);
            document.body.appendChild(script);
            };
            loadNext(0); // 开始加载第一个脚本
        },
      
    }
}
</script>

<style lang="less" scoped>
//@import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/frontend.min.css?ver=3.27.3';
@import './css/frontend.min.css';
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/modules/motion-fx.min.css?ver=3.27.3';
@import "./css/motion-fx.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/fadeInUp.min.css?ver=3.27.3';
@import "./css/fadeInUp.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-divider.min.css?ver=3.27.3';
@import "./css/widget-divider.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/css/widget-spacer.min.css?ver=3.27.3';
@import "./css/widget-spacer.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33263.css?ver=1734676771';
@import "./css/post-33263.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-261.css?ver=1734676771';
@import "./css/post-261.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-269.css?ver=1734676771';
@import "./css/post-269.css";
// 弹窗样式
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-5.css?ver=1734676771';
@import "./css/post-5.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/widget-nav-menu.min.css?ver=3.27.3';
@import "./css/widget-nav-menu.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor/assets/lib/animations/styles/slideInRight.min.css?ver=3.27.3';
@import "./css/slideInRight.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/plugins/elementor-pro/assets/css/conditionals/popup.min.css?ver=3.27.3';
@import "./css/popup.min.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-235.css?ver=1734676771';
@import "./css/post-235.css";
// @import 'https://www.pgsoftgaming.com/wp-content/uploads/elementor/css/post-33905.css?ver=1736761290';
@import "./css/post-33905.css";
.elementor-image-box-wrapper {
    
}
.elementor-image-box-img {
        margin: 0 auto !important;
    }
    #menu-1-7237f52 {
        li {
            color: #fff;
            &:first-child {
                color: #7EFDFF;
                font-weight: bold;
            }
            &:hover {
                color: #7EFDFF;
                font-weight: bold;
            }
        }
    }
    #headerBtnBox {
        transition: transform 0.3s ease; /* 添加过渡效果 */
        &:hover {
            transform: scale(1.1); /* 缩放为原来的 1.1 倍 */
        }
    }
    #swdtBox,#rejestBox {
        transition: transform 0.3s ease; /* 添加过渡效果 */
        &:hover {
            transform: scale(1.02); /* 缩放为原来的 1.1 倍 */
        }
    }
    #modelBox,#bwsBox {
        transition: transform 0.3s ease; /* 添加过渡效果 */
        &:hover {
            transform: scale(1.1); /* 缩放为原来的 1.1 倍 */
        }
    }
    #modelhandleBox {
        width: 380px;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 9999999;
        overflow: hidden;
    }

    @media only screen and (max-width: 768px) {
        #modelhandleBox {
        width: 100%;
        
    }
    }
   
</style>

<style>
body {
        margin: 0 !important;
        position: relative;
        /* top: -6px; */
    }


    
</style>
