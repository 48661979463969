<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2025-02-02 11:47:00
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2025-02-16 15:18:31
 * @FilePath: \sw_project\src\App.vue
 * @Description: 首页
-->
<template>
  <div id="app">
    <!-- <Index v-load-domendscripts></Index> -->
     <router-view></router-view>
  </div>
</template>

<script>
import Index from "./index/index";

var ElementorProFrontendConfig = {
                "ajaxurl": "https:\/\/www.pgsoftgaming.com\/wp-admin\/admin-ajax.php",
                "nonce": "76e2a3e79d",
                "urls": {
                    "assets": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/assets\/",
                    "rest": "https:\/\/www.pgsoftgaming.com\/wp-json\/"
                },
                "settings": {
                    "lazy_load_background_images": true
                },
                "popup": {
                    "hasPopUps": true
                },
                "shareButtonsNetworks": {
                    "facebook": {
                        "title": "Facebook",
                        "has_counter": true
                    },
                    "twitter": {
                        "title": "Twitter"
                    },
                    "linkedin": {
                        "title": "LinkedIn",
                        "has_counter": true
                    },
                    "pinterest": {
                        "title": "Pinterest",
                        "has_counter": true
                    },
                    "reddit": {
                        "title": "Reddit",
                        "has_counter": true
                    },
                    "vk": {
                        "title": "VK",
                        "has_counter": true
                    },
                    "odnoklassniki": {
                        "title": "OK",
                        "has_counter": true
                    },
                    "tumblr": {
                        "title": "Tumblr"
                    },
                    "digg": {
                        "title": "Digg"
                    },
                    "skype": {
                        "title": "Skype"
                    },
                    "stumbleupon": {
                        "title": "StumbleUpon",
                        "has_counter": true
                    },
                    "mix": {
                        "title": "Mix"
                    },
                    "telegram": {
                        "title": "Telegram"
                    },
                    "pocket": {
                        "title": "Pocket",
                        "has_counter": true
                    },
                    "xing": {
                        "title": "XING",
                        "has_counter": true
                    },
                    "whatsapp": {
                        "title": "WhatsApp"
                    },
                    "email": {
                        "title": "Email"
                    },
                    "print": {
                        "title": "Print"
                    },
                    "x-twitter": {
                        "title": "X"
                    },
                    "threads": {
                        "title": "Threads"
                    }
                },
                "facebook_sdk": {
                    "lang": "zh_CN",
                    "app_id": ""
                },
                "lottie": {
                    "defaultAnimationUrl": "https:\/\/www.pgsoftgaming.com\/wp-content\/plugins\/elementor-pro\/modules\/lottie\/assets\/animations\/default.json"
                }
            };
        

export default {
  name: 'App',
  components: {
    Index
  },
  data() {
    return {
      
    }
  },
  mounted() {
  
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
body {
  margin: 0 !important;
    padding:0;
}
#app {
 
}
</style>


